/**************************************************
 * Nombre:       Pdf_Cotizacion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Document, Font, Image, Page, StyleSheet, View,} from '@react-pdf/renderer';
import f700 from './Recursos/Geologica-Bold.ttf'
import cabecera from './Recursos/cabecera.png'
import pie from './Recursos/pie.png'
import TituloDatosCertificadoSubasta from "./Componentes/TituloDatosCertificadoSubasta";
import ParticipacionesUsuario from "./Componentes/ParticipacionesUsuario";
import ContratoSubasta from "./Componentes/ContratoSubasta";


const Pdf_Documento = ({imagen, participacion, tikets, qrHashSubasta, qrContratoString}) => {
    Font.register({
        family: 'Geologica', fonts: [
            {
                src: f700,
                fontWeight: 700
            }
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff',
            padding: 0,
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        texto: {
            fontSize: 20,
            fontFamily: 'Geologica'
        }

    });


    return (
        <Document style={{paddingBottom: 20}}>
            <Page size={'letter'} style={styles.page}>
                <View style={{...styles.gitc}}>

                    <View style={styles.git}>
                        <Image src={cabecera}/>
                    </View>


                    <View style={{
                        ...styles.gitc,
                        padding: 30
                    }}>

                        <View style={{...styles.git, justifyContent: 'center', marginTop: 0}}>
                            <TituloDatosCertificadoSubasta participacion={participacion}/>
                        </View>

                        <View style={{marginTop: 26}}/>

                        <View style={{...styles.git, justifyContent: 'center', marginTop: 0}}>
                            <ParticipacionesUsuario qr={imagen} participacion={participacion} tikets={tikets}/>
                        </View>


                    </View>


                    <View style={styles.git}>
                        <Image src={pie}/>
                    </View>


                </View>
            </Page>

            <Page size={'letter'} style={styles.page}>
                <View style={{...styles.gitc}}>

                    <View style={styles.git}>
                        <Image src={cabecera}/>
                    </View>


                    <View style={{
                        ...styles.gitc,
                        padding: 30
                    }}>

                        <View style={{...styles.git, marginTop: 0}}>
                            <ContratoSubasta contrato={participacion.valoresContrato} participacion={participacion}
                                             qrHashSubasta={qrHashSubasta} qrContratoString={qrContratoString}/>
                        </View>


                    </View>


                    <View style={styles.git}>
                        <Image src={pie}/>
                    </View>


                </View>
            </Page>


        </Document>
    )
}

export default Pdf_Documento;
