import React, {createContext, useEffect, useState} from 'react';
import './App.css';
import {Grid} from "@mui/material";
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";
import {iniciarAnaliticas} from "./Servicios/Analiticas/iniciarAnaliticas";
import Pagina from "./Pagina/Pagina";
import {contarColeccionWheres} from "./Servicios/BD/Contadores/contarColeccionWheres";
import {where} from "firebase/firestore";
import '@fontsource/geologica/300.css';
import '@fontsource/geologica/400.css';
import '@fontsource/geologica/500.css';
import '@fontsource/geologica/700.css';
import '@fontsource/geologica/800.css';
import {PERFILINVITADO} from "./Constantes";

export const usuarioContext = createContext();

function App() {
    const {email} = useEscucharAuth()
    const {usuario, setUsuario} = useEscucharUsuarioToCorreo({correo: email})
    const [recargarLocal, setRecargarLocal] = useState(false)
    const [numTickets, setNumTickets] = useState(0)

    const recargar = () => {
        setRecargarLocal(!recargarLocal)
        setNumTickets(0)

    }

    const valoresProvider = {
        usuario,
        setUsuario,
        setRecargarLocal,
        recargar,
        numTickets
    }

    const {Provider} = usuarioContext;


    useEffect(() => {

        iniciarAnaliticas()

        if (window.swUpdateReady) {
            window.swUpdateReady = false;
            window.stop();
            window.location.reload();
        }


    }, [])

    useEffect(() => {

        setNumTickets(0)
        if (usuario && usuario.nick && usuario.id) {


            let wheres = [where('idUsuario', '==', usuario.id), where('idSubasta', '==', 'none')]
            contarColeccionWheres({coleccion: 'tickets', wheres: wheres}).then((dox) => {
                if (dox.res) {
                    setNumTickets(dox.data)
                } else {
                    setNumTickets(0)
                }
            })
        } else {
            setUsuario(PERFILINVITADO)
            setNumTickets(0)
        }

    }, [usuario, recargarLocal])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{maxWidth: '100%'}}
        >


            <Provider value={valoresProvider}>
                <Pagina/>
            </Provider>

        </Grid>
    );
}

export default App;
