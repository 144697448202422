export const obtenerValoresDisponibles = (subasta) => {

    let valoresDisponibles = []

    if (subasta.participaciones) {

        let arr = subasta.valoresPosibles
        for (let i = 0; i < subasta.participaciones.length; i++) {
            let participacion = subasta.participaciones[i]
            let index = arr.indexOf(participacion.valor)
            delete arr[index]
        }


        return arr.filter(el => el !== '')

    } else {
        valoresDisponibles = subasta.valoresPosibles
        return valoresDisponibles
    }


}