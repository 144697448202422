/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountBalance,
    AccountCircle,
    Badge,
    Code, Flag,
    LocalOffer, Mail, MilitaryTech,
    PointOfSale,
    QrCode2,
    Subtitles
} from "@mui/icons-material";
import {PAQUETES, TIPOTRANSACCION} from "../../Constantes";
import {useEffect, useState} from "react";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import IngresoSelectPropId from "../../Modulo_formularios/Componentes/IngresoSelectPropId";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoSelectComponente from "../../Modulo_formularios/Componentes/IngresoSelectComponente";
import Tarjeta_Avatar from "../Tarjetas/Tarjeta_Avatar";

const Formulario_usuario = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectComponente dato={'imgCuadrado'} datoDos={'imgRectangular'}
                                             datoInputDos={'imgRectangular'} datoInput={'imgCuadrado'}
                                             coleccion={'avatares'}  {...props}
                                             Componente={Tarjeta_Avatar}
                                             editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nick name'} dato={'nick'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Mail} nombre={'Correo'} dato={'correo'}
                                  editable={false}
                                  {...props}

                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Flag} nombre={'Rango'}
                                   dato={'tipo'}  {...props}
                                   editable={false}
                                   opciones={['Noob', 'Ganador', 'Experimentado']}

                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={MilitaryTech} nombre={'# Medallas'}
                                  editable={false}
                                  dato={'numMedallas'}  {...props} type={'number'}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto lineas={7} nombre={'Perfil'} dato={'perfil'}  {...props}

                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_usuario