export const adaptador_participaciones = ({subasta, usuario, tickets}) => {


    let valoresContrato = JSON.parse(subasta.contratoString)


    let obj = {

        idUsuario: usuario.id,
        nick: usuario.nick,
        idSubasta: subasta.idSubasta,
        idSubastaId: subasta.id,
        img: subasta.img,
        producto: subasta.nombre,
        ticketsParticipando: tickets,
        valoresContrato: valoresContrato,
        hash: subasta.hash,
        contratoString: subasta.contratoString,
        fecha: new Date().getTime(),
        imgUsuario: usuario.imgCuadrado

    }


    return obj
}