/**************************************************
 * Nombre:       Dialogo_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logo_color.svg'
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import {theme} from "../../Tema";
import {useContext, useState} from "react";
import {ShoppingCart, Upload, WhatsApp} from "@mui/icons-material";
import {GRADIENTE, LINKMAP, LINKWASS} from "../../Constantes";
import {irUrl} from "../../Utilidades/irUrl";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Reto from "../Formularios/Formulario_Reto";
import {usuarioContext} from "../../App";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import Formulario_Datos from "../Formularios/Formulario_Datos";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";

const Dialogo_Producto_Medallas = ({cerrar, realizado, datos}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {abrirCargador, Cargador, cerrarCargador} = useLoaders({logo: logo})
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const cData = useContext(usuarioContext)

    const guardarDatos = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo pedido')
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.producto = datos.nombre
            obj.numMedallas = datos.numMedallas
            obj.nick = cData.usuario.nick
            obj.idUsuario = cData.usuario.id
            obj.estado = 'Nuevo'
            guardarDoc('pedidos', obj).then((dox) => {
                if (dox.res) {
                    restarStock()
                }
            })
        })
    }

    const restarMedallas = () => {

        let datosNuevos = {
            numMedallas: parseInt(cData.usuario.numMedallas) - datos.numMedallas
        }
        actualizarDoc('usuarios', cData.usuario.id, datosNuevos).then((dox) => {
            if (dox.res) {
                alert('Canje realizado con exito\nUn asesor se pondra en contacto contigo para confirmar el envio')
                realizado()
                cerrar()
            }
        })
    }

    const restarStock = () => {

        let datosNuevos = {
            stock: parseInt(datos.stock) - 1
        }
        actualizarDoc('productosKiosco', datos.id, datosNuevos).then((dox) => {
            if (dox.res) {
                restarMedallas()

            }
            cerrarCargador()
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: sCell ? 2 : 2}}
        >
            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -2}}>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: 24,
                        fontWeight: 800,
                        lineHeight: 1.1,
                        textAlign: 'center'
                    }}>{datos.nombre}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <img src={datos.img} width={'70%'} height={'100%'} style={{borderRadius: 20}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Typography sx={{
                    color: theme.palette.secondary.main,
                    fontSize: 16,
                    fontWeight: 300,
                    textAlign: 'center'
                }}>{datos.descripcion}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => irUrl(LINKWASS)}
                    startIcon={<WhatsApp/>}>Comprar con Dinero</Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Typography sx={{
                    color: theme.palette.secondary.main,
                    fontSize: 24,
                    fontWeight: 600,
                    textAlign: 'center'
                }}>{`Para canjearlo necesitas`} <br/>{`${datos.numMedallas}  Medallas`} </Typography>
            </Grid>


            {cData.usuario && cData.usuario.numMedallas && cData.numTickets && cData.numTickets > 0 && parseInt(cData.usuario.numMedallas) > datos.numMedallas ?


                <>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                        <Typography sx={{color: '#00000090'}}>Datos para el canje</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Formulario_Datos props={props}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                        <Button
                            fullWidth
                            onClick={() => guardarDatos()}
                            startIcon={<ShoppingCart/>}
                            sx={{background: GRADIENTE, px: 7}}>Canjear</Button>
                    </Grid>

                </>

                :

                <>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                        <Typography sx={{fontSize: 14, fontWeight: 300, color: '#00000080', textAlign: 'center'}}>El
                            numero de medallas no es suficiente o debes contar con 1 ticket disponible para realizar
                            el
                            canje</Typography>
                    </Grid>
                </>

            }


        </Grid>
    )

}
export default Dialogo_Producto_Medallas