/**************************************************
 * Nombre:       CuadroAvatar
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {GRADIENTE} from "../../../Constantes";
import logo_redondo from '../../../Recursos/logo_redondo_blanco.svg'

const CuadroAvatarParticipacion = ({item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
                background: GRADIENTE,
                borderRadius: '26%',
                border: 4,
                borderColor: item && item.ganadora ? 'red' : '#fff',
                boxShadow: 8
            }}
        >

            <img src={item && item.img ? item.img : logo_redondo} width={'100%'} height={'auto'}
                 style={{borderRadius: '20%'}}/>

        </Grid>
    )

}
export default CuadroAvatarParticipacion