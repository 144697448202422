/**************************************************
 * Nombre:       Dialogo_Politicas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";

const Dialogo_Politicas = ({datos}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {datos.items.map((it, index) => {
                        return (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{marginTop: 2}}
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{fontWeight: 700}}>{it.nombre}</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{fontWeight: 300}}>{it.descripcion}</Typography>
                                </Grid>

                            </Grid>
                        )
                    })}

                </Grid>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Politicas