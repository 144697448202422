/**************************************************
 * Nombre:       Formulario_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Mail, Password} from "@mui/icons-material";
import {theme} from "../../Tema";

const Formulario_Registro = (props) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{fontSize: 24, color: theme.palette.primary.main, fontWeight: 800}}>Registrandose</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <IngresoTexto Icono={AccountCircle} nombre={'Nickname'} dato={'nick'}  {...props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <IngresoTexto Icono={Mail} nombre={'Correo Electronico'} dato={'correo'}  {...props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <IngresoTexto Icono={Password} nombre={'Constraseña'} dato={'pass'}  type={'password'} {...props}/>
            </Grid>


        </Grid>
    )

}
export default Formulario_Registro    