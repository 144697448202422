/**************************************************
 * Nombre:       TituloDatosCertificadoSubasta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Font, Image, StyleSheet, View} from "@react-pdf/renderer";
import {React} from "react";
import f700 from "../Recursos/Geologica-Bold.ttf";
import f500 from "../Recursos/Geologica-Regular.ttf";
import TituloTextoPeq from "./TituloTextoPeq";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";


const ParticipacionesTickets = ({item, img}) => {
    Font.register({
        family: 'Geologica', fonts: [
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f500,
                fontWeight: 500
            }
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#EFEFEF',
            padding: 0
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        textoT: {
            fontSize: 10,
            fontFamily: 'Geologica',
            color: '#7F7F7F',
            fontWeight: 700
        },
        texto: {
            fontSize: 10,
            fontFamily: 'Geologica',
            color: '#7F7F7F',
            fontWeight: 500
        }

    });


    return (
        <View style={{...styles.git, alignItems: 'center'}}>

            <View style={{...styles.git, width: '10%'}}>
                <Image src={item.img} style={{width: 50, height: 50}}/>
            </View>

            <View style={{...styles.git, width: '90%'}}>
                <View style={styles.gitc}>

                    <TituloTextoPeq texto={item.id} titulo={'ID Ticket:'} difiere={true}/>

                    <TituloTextoPeq texto={formatoMoneda(item.valor)} titulo={'Valor Propuesto:'}/>

                    <TituloTextoPeq texto={item.hash}
                                    titulo={'Hash encriptacion:'}/>

                </View>
            </View>


        </View>
    )

}
export default ParticipacionesTickets