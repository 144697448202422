/**************************************************
 * Nombre:       TicketsSubastas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {LocalActivity} from "@mui/icons-material";
import {scrolearAComponente} from "../../../Utilidades/scrolearAComponente";
import {SECCIONES} from "../../../Constantes";
import {useDialogo} from "../../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Comprar from "../../Dialogos/Dialogo_Comprar";

const TicketsSubastas = ({Icono = LocalActivity, num = 0, nombre = 'Tickes', boton = 'Comprar'}) => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Comprar, titulo: 'Comprar Tickets'})


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={7} sm={7} xs={7} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Icono color={'action'} sx={{width: 46, height: 46}}/>
                        <Typography sx={{color: '#fff', fontSize: 32, fontWeight: 800, pl: 2}}>
                            {num} <span style={{fontSize: 14, fontWeight: 300}}>{nombre}</span>
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={5} sm={5} xs={5} sx={{justifyContent: 'flex-end'}}>
                <Button
                    onClick={() => abrir()}
                    sx={{
                        background: '#ffffff20',
                        pt: 1,
                        pb: 1.1,
                        border: 1,
                        borderColor: '#ffffff80',
                        px: 2,
                        fontSize: 14
                    }}>{boton}</Button>
            </Grid>


        </Grid>
    )

}
export default TicketsSubastas