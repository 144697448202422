/**************************************************
 * Nombre:       CabeceraLista
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import CuadroAvatar from "./CuadroAvatar";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {formatoFecha} from "../../../Utilidades/formatoFecha";
import {obtenerPosibilidades} from "../../../Utilidades/obtenerPosibilidades";
import {React} from "react";
import {useNavigate} from "react-router-dom";

const LineaListaParticipantesHome = ({item}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()

    const llevar = (e) => {
        navigate('/Perfil/' + item.idUsuario)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                px: sCell ? 0 : 6,
                py: 2,
                borderBottom: 1,
                borderColor: theme.palette.secondary.main
            }}
        >

            <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'center'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >

                    <Grid item container lg={3} sm={3} xs={3}
                          sx={{justifyContent: 'flex-start', marginBottom: sCell ? 2 : 0}}>
                        <CuadroAvatar item={{img: item.imgUsuario}} click={llevar}/>
                    </Grid>

                    <Grid item container lg={9} sm={9} xs={9} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{
                                    color: theme.palette.secondary.main,
                                    fontWeight: 800,
                                    fontSize: 20,
                                    lineHeight: 1.1
                                }}>{item.nick}</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: -0.5}}>
                                <Typography sx={{
                                    color: theme.palette.secondary.main,
                                    fontWeight: 300,
                                    fontSize: 16,
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                }}>{item.producto}</Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item container lg={2} sm={2} xs={6} sx={{justifyContent: sCell ? 'flex-start' : 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {formatoFecha(item.fecha)}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={6} sx={{justifyContent: sCell ? 'flex-end' : 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {item.idSubasta}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={6} sx={{justifyContent: 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {`${obtenerPosibilidades({
                        numTickets: item.valoresContrato.numeroTickets,
                        numPujas: item.ticketsParticipando.length
                    })}%`}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={6} sx={{justifyContent: 'flex-end', marginTop: sCell ? 2 : 0}}>
                <Button
                    onClick={() => navigate('/Detalle/' + item.idSubastaId)}
                    fullWidth sx={{
                    backgroundColor: '#2626ED10',
                    color: theme.palette.secondary.main,
                    px: 0,
                    boxShadow: 0,
                    pt: 0.5,
                    pb: 0.7
                }}>
                    Ver Subasta
                </Button>
            </Grid>


        </Grid>
    )

}
export default LineaListaParticipantesHome