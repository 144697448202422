/**************************************************
 * Nombre:       BarraCell
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import CuadroAvatar from "../Componentes/SubComponetes/CuadroAvatar";
import {theme} from "../../Tema";
import IndicadorHover from "../Componentes/SubComponetes/IndicadorHover";

const BarraCell = ({
                       usuario,
                       numInvitados,
                       abrir,
                       setOpen,
                       numTickets = 0,
                       numMedallas = 0,
                       numParticipaciones = 0
                   }) => {


    const getNick = (usu) => {

        if (usu.tipo === 'Invitado') {
            return `Invitado #${numInvitados}`
        } else {
            return usu.nick
        }

    }
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            backgroundColor: '#fff',
                            borderBottomRightRadius: 40,
                            borderBottomLeftRadius: 40,
                            px: 2,
                            py: 2,
                            boxShadow: '0px 0px 20px 5px #0000000A'
                        }}
                        spacing={2}
                    >

                        <Grid item container lg={3} sm={8} xs={6} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}

                            >

                                <Grid item container lg={3} sm={2} xs={5}
                                      onClick={() => setOpen(true)}
                                      sx={{
                                          justifyContent: 'flex-start',
                                          cursor: 'pointer'
                                      }}>
                                    <CuadroAvatar item={{img: usuario.imgCuadrado}}/>
                                </Grid>

                                <Grid item container lg={9} sm={7} xs={7} sx={{justifyContent: 'flex-start'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <Typography sx={{
                                                color: theme.palette.secondary.main,
                                                fontWeight: 800,
                                                fontSize: 16,
                                                lineHeight: 1.1
                                            }}>{getNick(usuario)}</Typography>
                                        </Grid>

                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start', marginTop: -0.5}}>
                                            <Typography sx={{
                                                color: theme.palette.secondary.main,
                                                fontWeight: 300,
                                                fontSize: 14
                                            }}>{usuario.tipo}</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>


                            </Grid>

                        </Grid>


                        <Grid item container lg={3} sm={4} xs={6} sx={{justifyContent: 'flex-end'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"

                            >


                                <Grid item container lg={4} sm={3} xs={3}
                                      sx={{justifyContent: 'flex-start'}}>
                                    <IndicadorHover nombre={'Tickets'} numero={numTickets}/>
                                </Grid>

                                <Grid item container lg={1} sm={1} xs={1} sx={{justifyContent: 'center'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        sx={{
                                            borderLeft: 1,
                                            height: 40,
                                            ml: 1.2,
                                            borderColor: theme.palette.primary.main
                                        }}
                                    />


                                </Grid>

                                <Grid item container lg={4} sm={3} xs={3}
                                      sx={{justifyContent: 'flex-start'}}>
                                    <IndicadorHover nombre={'Medal...'} numero={numMedallas}/>
                                </Grid>

                                <Grid item container lg={1} sm={1} xs={1}
                                      sx={{justifyContent: 'center', marginLeft: -0.5}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        sx={{
                                            borderLeft: 1,
                                            height: 40,
                                            ml: 1.2,
                                            borderColor: theme.palette.primary.main
                                        }}
                                    />


                                </Grid>

                                <Grid item container lg={4} sm={3} xs={3}
                                      sx={{justifyContent: 'flex-start'}}>
                                    <IndicadorHover nombre={'Partic...'} numero={numParticipaciones}/>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>


                </Grid>


            </Grid>


        </Grid>
    )

}
export default BarraCell    