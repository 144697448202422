/**************************************************
 * Nombre:       CabeceraLista
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {React, useEffect, useState} from "react";
import {obtenerDoc} from "../../../Servicios/BD/obtenerDoc";
import {obtenerPosibilidades} from "../../../Utilidades/obtenerPosibilidades";
import {useNavigate} from "react-router-dom";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Pdf_Certificado from "../../PDfsV2/Pdf_Certificado";
import CuadroAvatarParticipacion from "./CuadroAvatarParticipacion";
import QRCode from 'qrcode'

const LineaListaRetos = ({item, abrir}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                px: sCell ? 0 : 6,
                py: 2,
                borderBottom: 1,
                borderColor: theme.palette.secondary.main
            }}
            spacing={2}
        >


            <Grid item container lg={3} sm={3} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{
                            color: theme.palette.secondary.main,
                            fontWeight: 800,
                            fontSize: 18,
                            lineHeight: 1.1
                        }}>{item.nombre}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: 0}}>
                        <Typography sx={{
                            color: theme.palette.secondary.main,
                            fontWeight: 400,
                            fontSize: 14
                        }}>{`Recompensa max ${item.numMedallas} medallas`}</Typography>
                    </Grid>

                </Grid>


            </Grid>


            <Grid item container lg={7} sm={7} xs={8} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{
                    color: theme.palette.secondary.main, fontSize: 14, fontWeight: 300, display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    textOverflow: "ellipsis",
                }}>
                    {item.descripcion}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={4} sx={{justifyContent: 'center'}}>
                {item.estado !== 'Nuevo' ?

                    <Typography sx={{
                        color: theme.palette.secondary.main, fontSize: 16, fontWeight: 600
                    }}>
                        Enviado
                    </Typography>

                    :
                    <Button
                        onClick={() => abrir(item)}
                        fullWidth sx={{
                        backgroundColor: '#2626ED10',
                        color: theme.palette.secondary.main,
                        px: 3,
                        boxShadow: 0,
                        pt: 0.5,
                        pb: 0.7,
                        '&:hover': {
                            color: '#fff'
                        }
                    }}>
                        Detalles
                    </Button>
                }
            </Grid>


        </Grid>
    )

}
export default LineaListaRetos