/**************************************************
 * Nombre:       Indicadores_Perfil
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import {EmojiEvents, Gavel, LocalActivity, MilitaryTech, WhatsApp} from "@mui/icons-material";
import Tarjeta_Indicadores from "../../Tarjetas/Tarjeta_Indicadores";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {useContext, useEffect, useState} from "react";
import {usuarioContext} from "../../../App";
import {compartirWhatsApp} from "../../../Utilidades/CompartirWhatsApp";

const Indicadores_Perfil = ({indicadores, perfil}) => {
    const {sCell, masSM} = useResponsive()
    const [data, setData] = useState([])
    const usuario = useContext(usuarioContext).usuario

    useEffect(() => {


        let indis = [
            {
                nombre: 'tickets',
                numero: indicadores.numTickets ? indicadores.numTickets : 0,
                icono: LocalActivity
            },
            {
                nombre: 'Medallas',
                numero: indicadores.numMedallas ? indicadores.numMedallas : 0,
                icono: MilitaryTech
            },
            {
                nombre: 'Participaciones',
                numero: indicadores.numParticipaciones ? indicadores.numParticipaciones : 0,
                icono: Gavel
            },
            {
                nombre: 'Retos Ganados',
                numero: 0,
                icono: EmojiEvents
            },
        ]

        setData(indis)


    }, [indicadores]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: theme.palette.primary.main}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, py: masSM ? 6 : 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >

                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >

                                {data.map((item, index) => {
                                    return (
                                        <Grid item container lg={3} sm={3} xs={6} sx={{justifyContent: 'flex-start'}}>
                                            <Tarjeta_Indicadores item={item}/>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>

                        {usuario && usuario.id === perfil.id &&

                            <Grid item container lg={4} sm={12} xs={12}
                                  sx={{
                                      justifyContent: 'center',
                                      marginTop: masSM ? 0 : 3,
                                      marginBottom: masSM ? 0 : 1
                                  }}>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >

                                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                        <Button
                                            onClick={() => compartirWhatsApp(usuario.id)}
                                            startIcon={<WhatsApp/>}
                                            sx={{
                                                background: '#ffffff20',
                                                pt: 1,
                                                pb: 1.1,
                                                border: 1,
                                                borderColor: '#ffffff80'
                                            }}>Referenciar un amigo</Button>
                                    </Grid>

                                    <Grid item container lg={8} sm={12} xs={12}
                                          sx={{justifyContent: 'center', marginTop: 2}}>
                                        <Typography sx={{color: '#fff', fontSize: 14, textAlign: 'center'}}>
                                            Obtén medallas por cada amigo
                                            que cree su perfil con este link
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Grid>
                        }

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default Indicadores_Perfil

