/**************************************************
 * Nombre:       EconomiaColaborativa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {GRADIENTE, SECCIONES} from "../../Constantes";
import economia from '../../Recursos/img_economia_colaborativa.png'
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";


const EconomiaColaborativa = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: sCell ? 5 : 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row-reverse"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: sCell ? 'center' : 'flex-end'}}>
                            <img src={economia} width={'90%'} height={'auto'}/>
                        </Grid>


                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 3 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >


                                <Grid item container lg={8} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 300,
                                        fontSize: 18,
                                        lineHeight: 1,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>

                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 700,
                                        fontSize: 40,
                                        lineHeight: 1,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        Apalancamiento
                                    </Typography>
                                </Grid>

                                <Grid item container lg={10} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 300,
                                        fontSize: 16,
                                        lineHeight: 1.4,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        El apalancamiento es un modelo económico que te permite crecer financieramente
                                        de forma exponencial, y en Dream Free lo hemos adaptado para conectar a
                                        soñadores con tecnología premium. Cada participante aporta una pequeña fracción
                                        del valor total del producto disponible para subasta. Cuando se completa el
                                        número de participaciones, se activa la subasta, que mediante tecnología
                                        blockchain, un contrato inteligente y un HASH único, determina al beneficiario o
                                        ganador. Todos contribuyen, disfrutan y tienen la oportunidad de ganar un premio
                                        que realmente vale la pena.
                                    </Typography>
                                </Grid>

                                {masSM && <Grid item container lg={12} sm={12} xs={12}
                                                sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 8}}>
                                    <Button
                                        onClick={() => scrolearAComponente(SECCIONES[4])}
                                        sx={{background: GRADIENTE, px: 6}}>¿Cómo funciona?</Button>
                                </Grid>
                                }


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default EconomiaColaborativa