/**************************************************
 * Nombre:       EconomiaColaborativa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import logo from '../../Recursos/logo_jamssa.png'
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";


const Nosotros_Jamssa = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row-reverse"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-end'}}>
                            <img src={logo} width={'80%'} height={'auto'}/>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={8} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 700,
                                        fontSize: 40,
                                        lineHeight: 1
                                    }}>
                                        Jamssa Group SAS
                                    </Typography>
                                </Grid>

                                <Grid item container lg={10} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Typography sx={{
                                        color: theme.palette.secondary.main,
                                        fontWeight: 300,
                                        fontSize: 16,
                                        lineHeight: 1.4,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        Constituida legalmente en Colombia desde 2013, Jamssa Group SAS se ha
                                        consolidado como una empresa en servicios industriales y financieros, operando
                                        exitosamente en más de 30 países. Gracias a sólidas alianzas estratégicas y
                                        relaciones con socios, hemos ampliado nuestra presencia global de manera
                                        continua.
                                        <br/>
                                        <br/>
                                        Hoy, somos los impulsores de la marca Dream Free, una iniciativa que promueve la
                                        prosperidad social a través de un innovador modelo de apalancamiento. Nuestro
                                        objetivo es brindar apoyo a entusiastas y emprendedores, ofreciendo herramientas
                                        y oportunidades para que transformen sus vidas. Creemos en un futuro donde la
                                        colaboración y la innovación abran las puertas a nuevas posibilidades
                                        económicas, Jamssa Group y su equipo directivo responden al 100% por la
                                        operacion de Dream Free.


                                    </Typography>
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Nosotros_Jamssa