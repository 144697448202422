/**************************************************
 * Nombre:       Dialogo_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logo_color.svg'
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import {theme} from "../../Tema";
import {useState} from "react";
import {WhatsApp} from "@mui/icons-material";
import {GRADIENTE, LINKMAP, LINKWASS} from "../../Constantes";
import {irUrl} from "../../Utilidades/irUrl";

const Dialogo_Comprar = ({cerrar, realizado, datos}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {abrirCargador, Cargador, cerrarCargador} = useLoaders({logo: logo})
    const [textos, setTextos] = useState({...datos})


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: sCell ? 2 : 2}}
        >
            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -2}}>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: 24,
                        fontWeight: 800,
                        lineHeight: 1.1
                    }}>{textos.titulo}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Typography sx={{
                    color: theme.palette.secondary.main,
                    fontSize: 16,
                    fontWeight: 300
                }}>{textos.descripcion}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{
                    color: theme.palette.secondary.main,
                    fontSize: 16,
                    fontWeight: 300
                }}>
                    El Valor del ticket esta entre $ 2.900 y $4.000, este valor varia dependiendo del pack de tickets
                    comprado.
                    <br/>
                    <br/>
                    Pack 1: 1 ticket X $4.000
                    <br/>Pack 2: 5 tickets por $16.000
                    <br/>Pack 3: 10 tickets por $30.000
                    <br/>
                    Pack 4: 25 tickets por $75.000
                    <br/>Pack 5: 50 tickets por $150.000
                    <br/>Pack 6 High ticket: 100 tickets por
                    $290.000

                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 6}}>
                <Button
                    fullWidth
                    onClick={() => irUrl(LINKWASS)}
                    startIcon={<WhatsApp/>} sx={{background: GRADIENTE, px: 7}}>Compra por WhatsApp</Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3}}>
                <Button fullWidth
                        onClick={() => irUrl(LINKMAP)}
                        variant={'outlined'}>Comprar en oficina ( Dirigeme )</Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 6}}>
                <Typography sx={{
                    color: theme.palette.secondary.main,
                    fontSize: 16,
                    fontWeight: 300
                }}>
                    Los horarios de atención en oficina son: de lunes a viernes de 9 a 5 pm, previa agenda.
                </Typography>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Comprar