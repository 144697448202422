/**************************************************
 * Nombre:       Certificado
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import certi from '../../../Recursos/certificado.svg'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Certificado = ({pujas, item}) => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                border: 3,
                borderColor: '#6B5D7D',
                borderRadius: 4,
                px: sCell ? 4 : 6,
                py: sCell ? 4 : 6
            }}
        >

            <Grid item container lg={2} sm={12} xs={3} sx={{justifyContent: 'flex-start'}}>
                <img src={certi} width={'80%'}/>
            </Grid>

            <Grid item container lg={10} sm={12} xs={9} sx={{justifyContent: 'flex-start'}}>
                <Typography
                    sx={{
                        color: '#6B5D7D',
                        fontSize: 22,
                        fontWeight: 800,
                        lineHeight: 1.1
                    }}>
                    CERTIFICADO DE PARTICIPACION
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 2}}>

                <Typography
                    sx={{
                        color: '#6B5D7D',
                        fontSize: 16,
                        fontWeight: 300,
                        lineHeight: 1.1,
                        maxLines: 1,

                    }}>
                    <span style={{fontWeight: 600, marginRight: 4}}>
                        ID Subasta:
                    </span>
                    {item.idSubasta}
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 0}}>

                <Typography
                    sx={{
                        color: '#6B5D7D',
                        fontSize: 16,
                        fontWeight: 300,
                        lineHeight: 1.1,
                        maxLines: 1,

                    }}>
                    <span style={{fontWeight: 600, marginRight: 4}}>
                        Producto:
                    </span>
                    {item.nombre}
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 0}}>

                <Typography
                    sx={{
                        color: '#6B5D7D',
                        fontSize: 16,
                        fontWeight: 300,
                        lineHeight: 1.1,
                        maxLines: 1,

                    }}>
                    <span style={{fontWeight: 600, marginRight: 4}}>
                        Hash:
                    </span>
                    {item.hash.substring(0, 25) + '...'}
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 0}}>

                <Typography
                    sx={{
                        color: '#6B5D7D',
                        fontSize: 16,
                        fontWeight: 300,
                        lineHeight: 1.1,

                    }}>
                    <span style={{fontWeight: 600, marginRight: 4}}>
                        Valores elegidos:
                    </span>
                    {pujas.toString().replaceAll(',', ', ')}
                </Typography>
            </Grid>


        </Grid>
    )

}
export default Certificado