/**************************************************
 * Nombre:       Tarjeta_Producto_Construccion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import BarraTickets from "../Componentes/SubComponetes/BarraTickets";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useNavigate} from "react-router-dom";

const Tarjeta_Producto = ({item = {}}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{px: sCell ? 4 : 2, my: 4,}}
        >

            <ButtonBase
                onClick={() => navigate('/Detalle/' + item.id)}
                sx={{textAlign: 'left', borderRadius: 8}}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        backgroundColor: '#ffffff10',
                        border: 2,
                        borderColor: '#fff',
                        borderRadius: '32px',
                        boxShadow: '0px 0px 15px 0px #FFFFFF80',

                    }}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <img src={item.img} width={'100%'} height={'auto'}
                             style={{borderTopLeftRadius: 30, borderTopRightRadius: 30}}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{
                                backgroundColor: '#fff',
                                p: 2,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30
                            }}
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                <Typography sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 800,
                                    fontSize: 20,
                                    textAlign: 'center',
                                    lineHeight: 1.1,
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    textOverflow: "ellipsis",
                                    minHeight: 48,
                                }}>{item.nombre}</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'center', marginTop: 1}}>
                                <Typography sx={{color: theme.palette.primary.main, fontWeight: 300, fontSize: 12}}>
                                    {`ID Subasta: ${item.idSubasta}`}
                                </Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                                <BarraTickets
                                    numero={item.participaciones && item.participaciones.length ? item.participaciones.length : 0}
                                    total={item.numTickets}/>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                                <Typography sx={{color: theme.palette.primary.main, fontWeight: 800, fontSize: 12}}>
                                    {`${item.participaciones ? item.participaciones.length : 0} / ${item.numTickets}`}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </ButtonBase>
        </Grid>
    )

}
export default Tarjeta_Producto    