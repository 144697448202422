/**************************************************
 * Nombre:       Portada_Detalle_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {ArrowForwardIos, WhatsApp} from "@mui/icons-material";
import {GRADIENTE, LINKWASS} from "../../Constantes";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useContext, useEffect, useState} from "react";
import {usuarioContext} from "../../App";
import Tarjeta_Producto_Imagen from "../../Pagina/Tarjetas/Tarjeta_Producto_Imagen";

import {irUrl} from "../../Utilidades/irUrl";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Politicas from "../Dialogos/Dialogo_Politicas";
import BarraTicketsGrande from "./SubComponetes/BarraTicketsGrande";
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";
import {Splide, SplideSlide} from "@splidejs/react-splide";


const Portada_Detalle_Producto = ({item}) => {
    const {masSM, sCell} = useResponsive()
    const cData = useContext(usuarioContext)
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Politicas, titulo: 'Politicas de Entrega'})
    const [imagenes, setImagenes] = useState([])


    useEffect(() => {

        let arr = []
        arr.push(item.img)
        if (item.imgs) {
            for (let i = 0; i < item.imgs.length; i++) {
                arr.push(item.imgs[i])
            }
        }

        setImagenes(arr)

    }, [item]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 6}}>
                    <Grid
                        container
                        direction="row-reverse"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >

                        <Grid item container lg={5} sm={12} xs={12}
                              sx={{justifyContent: 'flex-end', marginBottom: 8, marginTop: 0}}>
                            <Splide aria-label="My Favorite Images Two" style={{width: '100%'}}
                                    options={{
                                        autoplay: false,
                                        speed: 3000,
                                        rewind: true,
                                        perPage: 1,
                                        arrows: true,
                                        gap: 10,
                                        pagination: true,
                                        drag: 'free',
                                        width: '100%'
                                    }}
                            >
                                {imagenes.map((it, index) => {
                                    return (
                                        <SplideSlide key={`tr${index}`}>
                                            <Tarjeta_Producto_Imagen imagen={it}/>
                                        </SplideSlide>
                                    )
                                })}


                            </Splide>


                        </Grid>

                        <Grid item container lg={7} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? -4 : 2}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={9} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Typography
                                        sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 600}}>
                                        {`ID Subasta: ${item.idSubasta}`}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontSize: 42,
                                        fontWeight: 800,
                                        lineHeight: 1.1
                                    }}>
                                        {item.nombre}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={9} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Typography
                                        sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 300}}>
                                        {item.descripcion}
                                    </Typography>
                                </Grid>

                                {item && item.link &&
                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                        <Button
                                            onClick={() => irUrl(item.link)}
                                            variant={'text'} endIcon={<ArrowForwardIos/>} sx={{px: 0, py: 0}}>Todas
                                            las Caracteristicas</Button>
                                    </Grid>
                                }


                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Typography
                                        sx={{
                                            color: theme.palette.primary.main,
                                            fontWeight: 800,
                                            fontSize: 22
                                        }}>{`${item.participaciones && item.participaciones.length} de ${item.numTickets}`}</Typography>
                                </Grid>

                                <Grid item container lg={10} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                    <BarraTicketsGrande total={item.numTickets}
                                                        numero={item.participaciones && item.participaciones.length}/>
                                </Grid>

                                <Grid item container lg={11} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >


                                        <Grid item container lg={6} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <Button
                                                onClick={() => scrolearAComponente('participa')}
                                                fullWidth sx={{background: GRADIENTE, px: 2, fontSize: 14}}>
                                                {`Participar ( 1 Ticket )`}
                                            </Button>
                                        </Grid>

                                        <Grid item container lg={6} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <Button
                                                onClick={() => irUrl(LINKWASS)}
                                                variant={'outlined'}
                                                startIcon={<WhatsApp/>}
                                                fullWidth sx={{px: 2, fontSize: 14}}>
                                                {`Mas información`}</Button>
                                        </Grid>


                                        <Grid item container lg={6} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                            <Button
                                                onClick={() => abrir()}
                                                variant={'text'} endIcon={<ArrowForwardIos/>} sx={{px: 0, py: 0}}>Política
                                                de
                                                entrega</Button>
                                        </Grid>


                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>


                </Grid>


            </Grid>

        </Grid>
    )

}
export default Portada_Detalle_Producto

const items = [1, 2, 3, 4]