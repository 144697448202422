import {obtenerTokenBarrer} from "../Utilidades/obtenerTokenBarrer";
import {IDPROYECTO} from "../Constantes";
import axios from "axios";
import logo from '../../logo.svg'

export const enviarNotificacionMultilplesTokens = ({arrToken, titulo, mensaje, img, funcion = null}) => {

    obtenerTokenBarrer().then((barrer) => {
        let authToken = barrer.data
        const fcm = `https://fcm.googleapis.com/v1/projects/${IDPROYECTO}/messages:send`


        for (let i = 0; i < arrToken.length;i++){
            let token = arrToken[i]
            // corregir si imagen
            let data = JSON.stringify({
                "message": {
                    "token": token,
                    "notification": {
                        "title": titulo,
                        "body": mensaje,

                    },
                    "webpush": {
                        "headers": {
                            "Urgency": "high"
                        },
                        "notification": {
                            "body": mensaje,
                            "requireInteraction": "true",
                            "badge": logo
                        }
                    }
                }
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: fcm,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authToken
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });

        }




    })
}