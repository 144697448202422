/**************************************************
 * Nombre:       Tarjeta_Indicadores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";

const Tarjeta_Indicadores = ({item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >

            <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                <item.icono sx={{fill: '#fff', width: 56, height: 56}}/>
            </Grid>

            <Grid item container lg={8} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{color: '#fff', fontWeight: 800, fontSize: 28}}>{item.numero}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -1.5}}>
                        <Typography sx={{color: '#fff', fontWeight: 300}}>{item.nombre}</Typography>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Indicadores