/**************************************************
 * Nombre:       TablaPujas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import Tarjeta_Puja from "../Tarjetas/Tarjeta_Puja";
import {GRADIENTE} from "../../Constantes";
import {LoadingButton} from "@mui/lab";
import {Circle, LocalActivity, PriceCheck} from "@mui/icons-material";
import {useContext} from "react";
import {usuarioContext} from "../../App";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";

const TablaPujas = ({pujas, borrarPuja}) => {
    const {sCell} = useResponsive()
    const cData = useContext(usuarioContext)

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        backgroundColor: '#2626ED1A',
                        px: 4,
                        py: 2,
                        borderRadius: 40
                    }}
                >

                    <Grid item container lg={6} sm={12} xs={8} sx={{justifyContent: 'flex-start'}}>
                        <PriceCheck sx={{fill: '#6B5D7D'}}/>
                        <Typography sx={{color: '#6B5D7D', pl: 1}}>Valor Propuesto</Typography>
                    </Grid>

                    <Grid item container lg={4} sm={12} xs={4} sx={{justifyContent: 'flex-end'}}>
                        <LocalActivity sx={{fill: '#6B5D7D'}}/>
                        <Typography sx={{color: '#6B5D7D', pl: 1}}>Costo</Typography>
                    </Grid>

                    {!sCell &&
                        <Grid item container lg={2} sm={12} xs={2} sx={{justifyContent: 'flex-end'}}>
                            <Circle sx={{fill: '#6B5D7D'}}/>
                        </Grid>
                    }

                </Grid>
            </Grid>

            {pujas.length > 0 &&
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={6}
                    >
                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 0.5}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{px: 2}}
                            >


                                {pujas.map((item, index) => {
                                    return (
                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                            <Tarjeta_Puja valor={item} borrarPuja={borrarPuja} index={index}/>
                                        </Grid>
                                    )
                                })}

                            </Grid>


                        </Grid>


                    </Grid>


                </Grid>
            }

        </Grid>
    )

}
export default TablaPujas