/**************************************************
 * Nombre:       Seccion_Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {useContext, useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";
import {ArrowBackIos} from "@mui/icons-material";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {theme} from "../../Tema";
import ListaParticipaciones from "../Componentes/ListaParticipaciones";
import {usuarioContext} from "../../App";
import Participaciones_Usuario from "../Componentes/Participaciones_Usuario";

const Seccion_Participaciones = () => {
    const {sCell, masSM} = useResponsive()
    const cData = useContext(usuarioContext)

    useEffect(() => {
        irArriba()

    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Participaciones_Usuario perfil={cData.usuario}/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Participaciones