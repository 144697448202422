/**************************************************
 * Nombre:       TituloDatosCertificadoSubasta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Font, Image, StyleSheet, Text, View} from "@react-pdf/renderer";
import {React} from "react";
import f700 from "../Recursos/Geologica-Bold.ttf";
import f500 from "../Recursos/Geologica-Regular.ttf";


const QrTexto = ({qr, texto}) => {
    Font.register({
        family: 'Geologica', fonts: [
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f500,
                fontWeight: 500
            }
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#EFEFEF',
            padding: 0
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        textoT: {
            fontSize: 10,
            fontFamily: 'Geologica',
            color: '#00000090',
            fontWeight: 700
        },
        texto: {
            fontSize: 12,
            fontFamily: 'Geologica',
            color: '#00000090',
            fontWeight: 500
        }

    });


    return (
        <View style={{...styles.gitc, width: '100%'}}>

            <View style={{...styles.gitc, justifyContent: 'center', width: '100%'}}>
                <Image src={qr} style={{width: 150, height: 150, margin: 'auto'}}/>
            </View>

            <View style={{...styles.gitc, justifyContent: 'center', width: '100%'}}>
                <Text style={{...styles.textoT, width: '100%', textAlign: 'center'}}>{texto}</Text>
            </View>

        </View>
    )

}
export default QrTexto