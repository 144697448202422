/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountBalance,
    AccountCircle,
    Badge,
    Code, Flag,
    LocalOffer, Mail, MilitaryTech,
    PointOfSale,
    QrCode2,
    Subtitles, Link, Build, Apartment, DirectionsCar, Signpost, CorporateFare, PhoneAndroid
} from "@mui/icons-material";
import {PAQUETES, TIPOTRANSACCION} from "../../Constantes";
import {useEffect, useState} from "react";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import IngresoSelectPropId from "../../Modulo_formularios/Componentes/IngresoSelectPropId";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoSelectComponente from "../../Modulo_formularios/Componentes/IngresoSelectComponente";
import Tarjeta_Avatar from "../Tarjetas/Tarjeta_Avatar";
import IngresoImagenCropCustom from "../../Modulo_formularios/Componentes/IngresoImagenCropCustom";

const Formulario_Datos = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombres y Apellidos'} dato={'nombre'}
                                  requerido={'El nombre es requerido'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Badge} nombre={'# Identificacion'} dato={'cc'}
                                  requerido={'El numero de identificacion es requerido'}

                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Num Celular'} dato={'celular'}
                                  requerido={'El numero celular es requerido'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Flag} nombre={'Departamento'} dato={'departamento'}
                                  requerido={'El departamento es requerido'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Apartment} nombre={'Ciudad'} dato={'ciudad'}
                                  requerido={'La ciudad es requerido'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Signpost} nombre={'Direccion'} dato={'direccion'}
                                  requerido={'La direccion es requerida'}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Indicaciones Adicionales'} dato={'indicaciones'} lineas={4}
                                  {...props}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Datos