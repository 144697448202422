/**************************************************
 * Nombre:       Tarjeta_Producto_Construccion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import BarraTickets from "../Componentes/SubComponetes/BarraTickets";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useNavigate} from "react-router-dom";
import prod from '../../Temporales/pat_uno.png'
import {formatoMoneda} from "../../Utilidades/formatoMoneda";

const Tarjeta_Producto_Medallas = ({item = {}, abrir}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{px: sCell ? 2 : 2, my: 2,}}
        >

            <ButtonBase
                onClick={() => abrir(item)}
                sx={{textAlign: 'left', borderRadius: 8}}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        border: 2,
                        borderColor: theme.palette.primary.main,
                        borderRadius: '32px',
                        boxShadow: '0px 0px 15px 0px #FFFFFF80',

                    }}
                >

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{
                              justifyContent: 'flex-start',
                              backgroundColor: '#fff',
                              borderTopLeftRadius: 30,
                              borderTopRightRadius: 30
                          }}>
                        <img src={item.img} width={'100%'} height={'auto'}
                             style={{borderTopLeftRadius: 30, borderTopRightRadius: 30}}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{
                                p: 2,
                                borderBottomLeftRadius: 30,
                                borderBottomRightRadius: 30
                            }}
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{
                                    color: '#fff',
                                    fontWeight: 800,
                                    fontSize: 20,
                                    textAlign: 'center',
                                    lineHeight: 1.1,
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                }}>{item.nombre}</Typography>
                            </Grid>


                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                <Typography sx={{color: '#fff', fontWeight: 400, fontSize: 14}}>
                                    {`${item.stock} en Stock`}
                                </Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-end', marginTop: 1}}>
                                <Typography sx={{color: '#fff', fontWeight: 400, fontSize: 14}}>
                                    {`Compra: ${formatoMoneda(item.valor)}`}
                                </Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-end', marginTop: 0}}>
                                <Typography sx={{color: '#fff', fontWeight: 400, fontSize: 14}}>
                                    {`Canje: ${item.numMedallas} Medallas`}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </ButtonBase>
        </Grid>
    )

}
export default Tarjeta_Producto_Medallas