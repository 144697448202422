/**************************************************
 * Nombre:       Tarjeta_Avatar
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {GRADIENTE} from "../../Constantes";

const Tarjeta_Avatar = ({item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{px: 8, py: 2}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    background: GRADIENTE,
                    borderRadius: '26%',
                    border: 4,
                    borderColor: '#fff',
                    boxShadow: 8,

                }}
            >
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <img src={item.imgCuadrado} width={'100%'} height={'auto'} style={{borderRadius: '25%'}}/>
                </Grid>

            </Grid>
        </Grid>
    )

}
export default Tarjeta_Avatar    