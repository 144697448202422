/**************************************************
 * Nombre:       ParrafoVerContrato
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Typography} from "@mui/material";
import {theme} from "../../../Tema";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {React, useEffect, useState} from "react";
import QRCode from "qrcode";
import {obtenerDoc} from "../../../Servicios/BD/obtenerDoc";
import Pdf_Contrato from "../../PDfsV2/Pdf_Contrato";

const ParrafoVerContrato = ({item}) => {
    const [qrHashSubasta, setQrHashSubasta] = useState('')
    const [qrContratoString, setQrContratoString] = useState('')

    const crearQrs = () => {


        QRCode.toDataURL(item.hash)
            .then(url => {

                setQrHashSubasta(url)
            })
            .catch(err => {

            })

        QRCode.toDataURL(item.contratoString)
            .then(url => {

                setQrContratoString(url)
            })
            .catch(err => {

            })

    }

    useEffect(() => {

        crearQrs()


    }, [item]);
    return (
        <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 300}}>
            {'Esta subasta no se gana proponiendo el precio mayor, se gana encontrando el precio que ya se le a sido asignado a '}
            <span style={{fontWeight: 600}}>{item.nombre}</span>
            {' con el Identificador de subasta '}
            <span style={{fontWeight: 600}}>{item.idSubasta}</span>
            {', el valor se encuentra entre '}
            <span style={{fontWeight: 600}}>{formatoMoneda(item.minimo)}</span>
            {' y '}
            <span style={{fontWeight: 600}}>{formatoMoneda(item.maximo)}</span>
            {' separados por la diferencia de $50. '}
            {' Todos los datos de esta subasta incluidos los datos necesarios para obtener el valor ganador se han encriptado en el siguiente contrato '}
            <span
                style={{
                    fontWeight: 600,
                    textDecoration: 'underline',
                    color: theme.palette.primary.main,

                }}>
                {qrContratoString && qrHashSubasta &&

                    < PDFDownloadLink
                        document={<Pdf_Contrato subasta={item}
                                                qrHashSubasta={qrHashSubasta}
                                                qrContratoString={qrContratoString}
                        />}
                        fileName={"Contrato de Subasta ID " + item.idSubasta.replaceAll('.','_')}
                        style={{width: '100%'}}>
                        {'Ver Contrato'}
                    </PDFDownloadLink>
                }
                    </span>


        </Typography>
    )

}
export default ParrafoVerContrato