/**************************************************
 * Nombre:       Tarteja_Documento
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import 'photoswipe/dist/photoswipe.css'
import {Gallery, Item} from 'react-photoswipe-gallery'

const Tarteja_Documento = ({item}) => {

    let abrir = () => {
        let ima = document.getElementById('ima1')
        ima.click()
        console.log(ima)

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
                boxShadow: '0px 0px 20px 0px #00000014',
                borderRadius: 8,
                py: 8,
                px: 6
            }}
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <img src={item.img} height={120} width={'auto'}/>
            </Grid>

            <Gallery
            >

                {item.imgs.map((it, index) => {
                    return (
                        <Item
                            original={it}
                            thumbnail={it}
                            width="620"
                            height="768"
                        >
                            {({ref, open}) => (
                                <img id={'ima' + index} style={{display: 'none'}} ref={ref} onClick={open}
                                     src={it}/>
                            )}
                        </Item>
                    )
                })}


            </Gallery>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Button
                    onClick={() => abrir()}
                    fullWidth variant={'outlined'} sx={{px: 2}}>{item.nombre}</Button>
            </Grid>

        </Grid>
    )

}
export default Tarteja_Documento