export const POLITICAS = [
    {
        nombre: 'Política de Entrega',
        items: [
            {
                nombre: 'Entrega de productos:',
                descripcion: '-Los beneficiarios de nuestras subastas pueden recoger su producto en la oficina de Dream Free en Bogotá, presentando el Hash proporcionado en la plataforma y su documento de identidad.\n' +
                    'Para quienes requieran mensajería, deben solicitar el envío por courrier contactando al servicio al cliente de Dream Free. Los costos de embalaje, courrier y seguro de producto deberán ser cubiertos por el beneficiario.'
            },
            {
                nombre: 'Opciones de envío:',
                descripcion: '-Sugerimos siempre la opción de envío más conveniente en términos de calidad, garantías y seguros.',
            },
            {
                nombre: 'Seguimiento del envío:',
                descripcion: '-Ofrecemos una herramienta de seguimiento en tiempo real para que los compradores puedan monitorear el estado de su entrega desde el momento en que se envía hasta que llega a su destino.',
            },
            {
                nombre: 'Agenda de entrega:',
                descripcion: '-Los beneficiarios pueden programar la recogida de sus productos en la oficina de Dream Free de lunes a viernes, de 9 am a 5 pm, o solicitar el envío por mensajería en el mismo horario a través de nuestros canales oficiales de contacto.',
            },
            {
                nombre: 'Política de devoluciones y reembolsos:',
                descripcion: '-No se realizan devoluciones por la compra de tickets o tickets utilizados. Si el producto presenta defectos, el beneficiario debe ponerse en contacto directamente con la marca o el fabricante.',
            },
            {
                nombre: 'Responsabilidad en el envío:',
                descripcion: '-Dream Free se responsabiliza del empaque (embalaje) del producto, su entrega a la empresa de mensajería, la firma del control de salida, la contratación del seguro de envío y el reporte de la guía al beneficiario para su seguimiento.',
            },
            {
                nombre: 'Condiciones del producto:',
                descripcion: '- Los artículos se entregarán en sus empaques originales, sellados y con garantía directa de la marca.  El cliente podrá abrir los productos en nuestra oficina y verificar su originalidad, falla de fábrica, números de serie o identificadores del producto para su tranquilidad y satisfacción.  ',
            },
            {
                nombre: 'NOTA:',
                descripcion: 'Todos los artículos publicados en nuestra página web DreamFree.tech se encuentran en stock, así que no habrá tiempos de espera por parte de Dream Free para la entrega del producto.-'
            }

        ]

    },
    {
        nombre: 'Políticas de calidad',
        items: [
            {
                nombre: 'Políticas de calidad:',
                descripcion: '-Todos los productos de Dream Free pasan por un proceso de verificación para determinar que estén sellados y sin novedades en sus empaques originales. \n' +
                    '-Los productos tendrán una descripción clara en nuestra plataforma.\n' +
                    '\n-Los artículos se entregarán en sus empaques originales sin ningún embalaje adicional, sellados y con garantía directa de la marca (Aplica solo para los productos recogidos directamente en la oficina).'
            },
            {
                nombre: 'Política de reclamación: ',
                descripcion: 'Dream free se adhiere a la política de reclamación de cada marca, donde los participantes al encontrar una novedad deben seguir los lineamientos de la marca para reclamaciones, Dream free podrá llevar métricas y análisis por las novedades presentadas. '
            }
        ]

    },
    {
        nombre: 'Política de precios',
        items: [
            {
                nombre: 'Transparencia en los Precios:',
                descripcion: 'Todos los precios de los productos y las tarifas asociadas con la subasta serán  visibles para los participantes desde el inicio. Esto incluye el precio inicial de la subasta, los incrementos mínimos de las ofertas, y cualquier comisión o tarifa adicional. El beneficiario debe pagar el valor subastado en tickets, efectivo o cualquier otro medio de pago aceptado por Dream Free, previo o el día de la entrega del producto.',

            },
            {
                nombre: 'Precios Competitivos:',
                descripcion: 'Los precios de los productos serán competitivos con respecto al mercado. Con herramientas de evaluación de mercado se asegurará que los productos en subasta tengan un precio atractivo para los participantes.',

            },
            {
                nombre: 'Tarifas de la Plataforma: ',
                descripcion: ' Los tickets emitidos para cada subasta representan  los costos ya asociados a la plataforma. \n' +
                    '-Los proveedores pueden ofrecer negociaciones a Dream free y dar bonificaciones adicionales a nuestros participantes. ',

            },
            {
                nombre: 'Ofertas y Descuentos:',
                descripcion: 'Dream Free ofrece descuentos adicionales en las tarifas para productos de alta demanda, promociones y retos en subastas.',

            },
        ]

    },
]

