export const prepararPujas = (pujas, usuario, tickets) => {

    let arr = []
    for (let i = 0; i < pujas.length; i++) {
        let val = {
            valor: pujas[i],
            idUsuario: usuario.id,
            nick: usuario.nick,
            tipo: usuario.tipo,
            fecha: new Date().getTime(),
            idTicket: tickets[i].id,
            hashTicket: tickets[i].hash,
            codigoTransferencia: tickets[i].codigoTransferencia,
            imgCuadrado: usuario.imgCuadrado
        }
        arr.push(val)
    }

    console.log(arr)
    return arr

}