/**************************************************
 * Nombre:       Participaciones_Usuario
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import ListaParticipaciones from "./ListaParticipaciones";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useContext} from "react";
import {usuarioContext} from "../../App";
import ListaRetos from "./ListaRetos";

const Tabla_Retos = ({perfil}) => {
    const {sCell, masSM} = useResponsive()


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: sCell ? 32 : 40,
                                lineHeight: 1,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Gana Medallas
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 300,
                                fontSize: 16,
                                lineHeight: 1.4,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Cumple los siguientes retos y gana medallas para intercambiarlas en el Kiosco de medalla
                            </Typography>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginBottom: 4}}>
                    <ListaRetos usuario={perfil}/>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Tabla_Retos