/**************************************************
 * Nombre:       MenuVertical
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Drawer, Grid, IconButton, Typography} from "@mui/material";
import logo from '../../Recursos/logo_blanco.svg'
import {
    AccountCircleOutlined,
    KeyboardArrowLeft,
    LocalActivityOutlined,
    MilitaryTechOutlined, StorefrontSharp, ThumbDownAltOutlined, ThumbUpAlt, ThumbUpAltOutlined,
    WhatsApp
} from "@mui/icons-material";
import CuadroAvatar from "../Componentes/SubComponetes/CuadroAvatar";
import {useContext} from "react";
import {usuarioContext} from "../../App";
import PerfilAvatar from "./ComponentesMenu/PerfilAvatar";
import TicketsSubastas from "./ComponentesMenu/TicketsSubastas";
import {funSalir} from "../../Servicios/Auth/funSalir";
import {useLocation, useNavigate} from "react-router-dom";
import {LINKWASS, SECCIONES} from "../../Constantes";
import {obtenerSeccionUrl} from "../../Utilidades/obtenerSeccionUrl";
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Login from "../Dialogos/Dialogo_Login";
import {irUrl} from "../../Utilidades/irUrl";

const MenuVertical = ({open, setOpen}) => {
    const cData = useContext(usuarioContext)
    const {sCell} = useResponsive()
    const navigate = useNavigate()
    const location = useLocation()
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Login, titulo: '', realizado: () => {
        }
    })

    const irComponente = (id) => {

        setTimeout(() => {
            setOpen(false)
        }, 1000)

        let seccion = obtenerSeccionUrl({path: location.pathname, index: 1})
        if (id === 'Subastas') {
            navigate('/' + id)
        } else if (id === 'Nosotros') {
            navigate('/' + id)
        } else if (seccion === 'Home') {
            scrolearAComponente(id)
        } else {
            navigate('/Home')
            setTimeout(() => {
                scrolearAComponente(id)
            }, 500)
        }


    }

    return (
        <Drawer open={open} onClose={() => setOpen(false)}>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{width: 350, py: 3, px: 2}}
            >

                <Dialogo/>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <img src={logo} width={'80%'} height={'auto'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                    <IconButton
                        onClick={() => setOpen(false)}
                    >
                        <KeyboardArrowLeft color={'action'}/>
                    </IconButton>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4, px: 2}}>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >

                        <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-start'}}>
                            <CuadroAvatar item={{img: cData.usuario.imgCuadrado}}/>
                        </Grid>

                        <Grid item container lg={8} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                            <PerfilAvatar usuario={cData.usuario}/>
                        </Grid>

                        {cData.usuario && cData.usuario.nick !== 'Invitado' &&
                            <>
                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <ButtonBase
                                        onClick={() => navigate('/Perfil')}
                                    >
                                        <AccountCircleOutlined color={'action'}/>

                                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 600, pl: 2}}>
                                            Mi Perfil
                                        </Typography>
                                    </ButtonBase>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                    <ButtonBase
                                        onClick={() => navigate('/Participaciones')}
                                    >
                                        <MilitaryTechOutlined color={'action'}/>

                                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 600, pl: 2}}>
                                            Mis Participaciones
                                        </Typography>
                                    </ButtonBase>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                    <ButtonBase
                                        onClick={() => navigate('/Retos')}
                                    >
                                        <ThumbUpAltOutlined color={'action'}/>

                                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 600, pl: 2}}>
                                            Retos
                                        </Typography>
                                    </ButtonBase>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                    <ButtonBase
                                        onClick={() => navigate('/Kiosco')}
                                    >
                                        <StorefrontSharp color={'action'}/>

                                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 600, pl: 2}}>
                                            Kiosco de Medallas
                                        </Typography>
                                    </ButtonBase>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                    <ButtonBase
                                        onClick={() => irUrl(LINKWASS)}
                                    >
                                        <WhatsApp color={'action'}/>

                                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 600, pl: 2}}>
                                            Soporte
                                        </Typography>
                                    </ButtonBase>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{
                                          justifyContent: 'flex-start',
                                          borderTop: 2,
                                          borderColor: '#fff',
                                          ml: 2,
                                          marginTop: 4
                                      }}/>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <TicketsSubastas Icono={LocalActivityOutlined}
                                                     num={cData.numTickets ? cData.numTickets : 0}/>
                                </Grid>
                            </>
                        }


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{
                                  justifyContent: 'flex-start',
                                  borderTop: 2,
                                  borderColor: '#fff',
                                  ml: 2,
                                  marginTop: 4
                              }}/>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -3}}>
                            <Typography sx={{fontSize: 14, fontWeight: 300, color: '#fff'}}>Secciones</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >

                                {SECCIONES.map((it, index) => {

                                    if (index < 3) {
                                        return (
                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <ButtonBase
                                                    onClick={() => irComponente(it)}
                                                >
                                                    <Typography
                                                        sx={{color: '#fff', fontSize: 18, fontWeight: 600, pl: 2}}>
                                                        {it}
                                                    </Typography>
                                                </ButtonBase>
                                            </Grid>
                                        )
                                    }


                                })}


                            </Grid>

                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                            {cData.usuario && cData.usuario.nick !== 'Invitado' ?

                                <Button
                                    onClick={() => {
                                        cData.recargar()
                                        funSalir()
                                        setOpen(false)

                                    }}
                                    variant={'outlined'} color={'action'} fullWidth>Salir</Button>
                                :
                                <Button
                                    onClick={() => abrir()}
                                    variant={'outlined'} color={'action'} fullWidth>Ingresar</Button>


                            }
                        </Grid>

                    </Grid>

                </Grid>

            </Grid>
        </Drawer>
    )

}
export default MenuVertical