/**************************************************
 * Nombre:       CabeceraLista
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {
    CategoryOutlined,
    CommentOutlined,
    EventAvailableOutlined, Gavel,
    MonetizationOnOutlined, Percent,
    PermIdentityOutlined, PriceCheck, QrCode2
} from "@mui/icons-material";
import {theme} from "../../../Tema";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const CabeceraListaParticipantesHome = () => {
    const {sCell} = useResponsive()


    const IconoTitulo = ({Icono, titulo}) => {
        return (
            <Typography sx={{color: theme.palette.secondary.main}}>
                    <span style={{marginBottom: -10, marginTop: 4, paddingRight: 6}}>
                        <Icono color={'secondary'} sx={{marginBottom: -0.7, fill: ''}}/>
                    </span>
                {titulo}
            </Typography>
        )
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
                backgroundColor: '#2626ED10',
                px: 6,
                py: 2,
                borderRadius: 30
            }}
        >

            <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                <IconoTitulo titulo={'Usuario'} Icono={PermIdentityOutlined}/>
            </Grid>

            <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'center'}}>
                <IconoTitulo titulo={'Fecha'} Icono={EventAvailableOutlined}/>
            </Grid>

            <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'center'}}>
                <IconoTitulo titulo={'Id Subasta'} Icono={QrCode2}/>
            </Grid>

            <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'center'}}>
                <IconoTitulo titulo={'Probabilidad'} Icono={Percent}/>
            </Grid>

            <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'flex-end'}}>
                <IconoTitulo titulo={'Ver Subasta'} Icono={Gavel}/>
            </Grid>


        </Grid>
    )

}
export default CabeceraListaParticipantesHome