/**************************************************
 * Nombre:       EconomiaColaborativa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import como from '../../Recursos/img_como_funciona.png'
import {irUrl} from "../../Utilidades/irUrl";


const ComoFunciona = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: sCell ? 4 : 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <img src={como} width={'90%'} height={'auto'}/>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 3 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 700,
                                        fontSize: 40,
                                        lineHeight: 1,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        ¿Cómo Funciona?
                                    </Typography>
                                </Grid>

                                <Grid item container lg={10} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 300,
                                        fontSize: 16,
                                        lineHeight: 1.4,
                                        textAlign: 'left'
                                    }}>
                                        1. Ingresa o regístrate.
                                        <br/>
                                        <br/>
                                        2. Selecciona el producto:
                                        <br/>
                                        -En la página principal (subastas activas), elige el producto que deseas
                                        adquirir y haz clic en "Participar" (1 ticket).
                                        -Puedes utilizar tickets que podrás comprar o ganar desde tu perfil.
                                        <br/>
                                        <br/>
                                        3. Realiza tu puja: <br/>

                                        -Elige el valor que consideres adecuado para el producto seleccionado haciendo
                                        clic en "PUSH" o "HIT".
                                        -Luego, confirma tu participación. Cada puja tendrá un contrato único.
                                        <br/>
                                        <br/>
                                        4. Activación de la subasta:
                                        -La subasta se activará automáticamente una vez que se complete el número
                                        establecido de participantes o tickets.
                                        <br/>
                                        <br/>
                                        5. Determinación del ganador:
                                        <br/>
                                        -El resultado o ticket beneficiario será determinado por un Smart Contract
                                        (contrato inteligente).
                                        <br/>
                                        <br/>
                                        6. Notificación y entrega:
                                        <br/>
                                        -El beneficiario será notificado y podrá recoger el producto en nuestras
                                        oficinas o solicitar el envío por courier.
                                        Todo el proceso está respaldado por la seguridad de la tecnología blockchain.

                                    </Typography>
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'center', marginTop: 6}}>
                                    <Button

                                        onClick={() => irUrl('https://dreamfree.tech/recursos/Contrato_inteligente_Dream_Free.pdf')}
                                        variant={'outlined'} sx={{px: 7, pt: 1, pb: 1.2}}>Ver Contrato inteligente
                                    </Button>
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default ComoFunciona