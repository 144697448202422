/**************************************************
 * Nombre:       Tarjeta_Producto_Imagen
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {GRADIENTE} from "../../Constantes";
import Zoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'

const Tarjeta_Producto_Imagen = ({imagen}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: 2, pb: 4, pt: 4}}
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{background: GRADIENTE, borderRadius: '10%', border: 8, borderColor: '#fff', boxShadow: 2, }}
            >

                <Zoom>
                    <img src={imagen} width={'100.3%'} height={'auto'} style={{borderRadius: '9%', marginBottom: -3, marginTop: -3}}/>
                </Zoom>

            </Grid>
        </Grid>
    )

}
export default Tarjeta_Producto_Imagen