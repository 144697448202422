/**************************************************
 * Nombre:       Tarjeta_Puja
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, IconButton, Typography} from "@mui/material";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {Delete} from "@mui/icons-material";

const Tarjeta_Puja = ({valor,borrarPuja, index}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{borderBottom: 1, borderColor: '#6B5D7D', py: 1, px: 2}}
        >

            <Grid item container lg={5} sm={12} xs={6} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{color: '#6B5D7D', fontSize: 18, fontWeight: 300}}>{formatoMoneda(valor)}</Typography>
            </Grid>

            <Grid item container lg={5} sm={12} xs={4} sx={{justifyContent: 'flex-end'}}>
                <Typography sx={{color: '#6B5D7D', fontSize: 18, fontWeight: 800}}>1 Ticket</Typography>
            </Grid>

            <Grid item container lg={2} sm={12} xs={2} sx={{justifyContent: 'flex-end'}}>
                <IconButton
                    onClick={() => borrarPuja(index)}
                    sx={{p: 0.4}} >
                    <Delete sx={{fill: '#6B5D7D90'}}/>
                </IconButton>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Puja