/**************************************************
 * Nombre:       Documentos_Legales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {DOCUMENTOS} from "../../Constantes";
import Tarteja_Documento from "../Tarjetas/Tarteja_Documento";
import {theme} from "../../Tema";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";

const Documentos_Legales = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: 40,
                                lineHeight: 1,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Documentos Legales
                            </Typography>
                        </Grid>

                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: 'center', marginTop: 4}}>
                            <Typography sx={{
                                color: theme.palette.secondary.main,
                                fontWeight: 300,
                                fontSize: 16,
                                lineHeight: 1.4,
                                textAlign: 'center'
                            }}>
                                Constituidos legalmenete en Colombia con la finalidad de llegar a todos los rincones de
                                latinoamerica
                            </Typography>
                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                spacing={6}
                            >

                                {DOCUMENTOS.map((item, index) => {
                                    return (
                                        <Grid item container lg={4} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <Tarteja_Documento item={item}/>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Documentos_Legales