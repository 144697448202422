/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Barra from "./Barra/Barra";
import Home from "./Secciones/Home";
import Footer from "./Footer";
import Seccion_Nosotros from "./Secciones/Seccion_Nosotros";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {useContext, useEffect} from "react";
import {usuarioContext} from "../App";
import Seccion_Participaciones from "./Secciones/Seccion_Participaciones";
import Detalle_Producto from "./Secciones/Detalle_Producto";
import Seccion_Perfil from "./Secciones/Seccion_Perfil";
import Seccion_Subastas from "./Secciones/Seccion_Subastas";
import {obtenerStringLocal} from "../Servicios/BDLocal/obtenerStringLocal";
import Seccion_Retos from "./Secciones/Seccion_Retos";
import Seccion_Kiosco from "./Secciones/Seccion_Kiosco";

const Pagina = () => {
    const cData = useContext(usuarioContext)


    useEffect(() => {
        let ref = obtenerStringLocal({clave: 'referenciado'})
        console.log(ref)
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Router>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', zIndex: 2}}>
                    <Barra usuario={cData.usuario}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', zIndex: 1}}>

                    <Routes>

                        <Route path={"/"} element={<Home/>}/>
                        <Route path={"/Home"} element={<Home/>}/>
                        <Route path={"/*"} element={<Home/>}/>
                        <Route path={"/Perfil/:id/:ref"} element={<Home/>}/>
                        <Route path={"/Detalle/:id"} element={<Detalle_Producto/>}/>
                        <Route path={"/Nosotros"} element={<Seccion_Nosotros/>}/>
                        <Route path={"/Participaciones"} element={<Seccion_Participaciones/>}/>
                        <Route path={"/Perfil"} element={<Seccion_Perfil/>}/>
                        <Route path={"/Perfil/:id"} element={<Seccion_Perfil/>}/>
                        <Route path={"/Subastas"} element={<Seccion_Subastas/>}/>
                        <Route path={"/Retos"} element={<Seccion_Retos/>}/>
                        <Route path={"/Kiosco"} element={<Seccion_Kiosco/>}/>

                    </Routes>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 3}}>
                    <Footer/>
                </Grid>

            </Router>

        </Grid>
    )

}
export default Pagina    