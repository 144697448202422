/**************************************************
 * Nombre:       Dialogo_Politicas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {Email} from "@mui/icons-material";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";


const Dialogo_Banner = ({realizado, cerrar}) => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', px: sCell ? 0 : 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 0}}>
                        <Typography sx={{
                            color: theme.palette.primary.main,
                            fontSize: sCell ? 24 : 32,
                            fontWeight: 600,
                            lineHeight: 1,
                            textAlign: 'center'
                        }}>Crea tu
                            cuenta ahora</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                        <Typography sx={{
                            color: theme.palette.primary.main,
                            fontSize: sCell ? 14 : 16,
                            fontWeight: 300,
                            textAlign: 'center',
                            lineHeight: 1.1
                        }}>Solo
                            necesitas un correo electronico para participar de estas compras interactivas.</Typography>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                        <Typography
                            sx={{color: theme.palette.secondary.main, fontSize: sCell ? 14 : 16, fontWeight: 300}}>
                            · Subastas al 15% de su valor comercial <br/>
                            · Canje de productos por participaciones <br/>
                            · Premios por completar retos o tareas <br/>
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', marginTop: 4, marginBottom: 4}}>
                        <Button
                            onClick={() => {
                                cerrar()
                                realizado({registro: true})
                            }}
                            startIcon={<Email/>}>Registrarme</Button>
                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Banner