import {fire} from "../../fire";
import {
    setDoc,
    doc,
    deleteDoc,
    onSnapshot,
    updateDoc,
    writeBatch,
    getDocs,
    collection,
    arrayUnion
} from 'firebase/firestore'

export const agregarMultiplesItemArrayDoc = ({coleccion, id, nombreArray, arrObjs}) => {

    let batch = writeBatch(fire)

    for (let i = 0; i < arrObjs.length; i++) {

        let obj = arrObjs[i]
        let refe = doc(fire, coleccion, id)
        batch.update(refe, {[nombreArray]: arrayUnion(obj)})
    }

    return new Promise(resolve => {

        if (arrObjs.length <= 0) {
            return resolve({res: true, data: 0})
        }

        batch.commit().then((dox) => {
            return resolve({res: true, dox})
        }).catch((err) => {
            return resolve({res: false, data: err.message})
        })
    })




}