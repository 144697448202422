/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {CONTACTOS, GRADIENTE, SECCIONES} from "../Constantes";
import Tarjeta_Contactos_Footer from "./Tarjetas/Tarjeta_Contactos_Footer";
import logo from '../Recursos/logo_blanco.svg'
import {scrolearAComponente} from "../Utilidades/scrolearAComponente";
import {theme} from "../Tema";
import {useDialogo} from "../Modulo_dialogos/Hooks/useDialogo";
import {useLocation, useNavigate} from "react-router-dom";
import {obtenerSeccionUrl} from "../Utilidades/obtenerSeccionUrl";
import Dialogo_Politicas from "./Dialogos/Dialogo_Politicas";
import {useState} from "react";
import {POLITICAS} from "../Politicas";


const Footer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [titulo, setTitulo] = useState('')
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Politicas, titulo: titulo})

    const abrirPoliticas = (e) => {
        setTitulo(e.nombre)
        abrir(e)
    }
    const irComponente = (id) => {


        let seccion = obtenerSeccionUrl({path: location.pathname, index: 1})


        if (id === 'Subastas') {
            navigate('/' + id)
        } else if (id === 'Nosotros') {
            navigate('/' + id)
        } else if (seccion === 'Home') {
            scrolearAComponente(id)
        } else {
            navigate('/Home')
            setTimeout(() => {
                scrolearAComponente(id)
            }, 500)
        }


    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{background: GRADIENTE}}
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, py: masSM ? 12 : 8}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >

                        <Grid item container lg={2} sm={3} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{color: '#fff', fontWeight: 800}}>Links rápidos</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {SECCIONES.map((item, index) => {
                                            return (
                                                <Grid item container lg={12} sm={12} xs={12}
                                                      sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                                    <ButtonBase
                                                        onClick={() => irComponente(item)}
                                                    >
                                                        <Typography sx={{color: '#fff', fontWeight: 300}}>
                                                            {item}
                                                        </Typography>
                                                    </ButtonBase>

                                                </Grid>
                                            )
                                        })}


                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={4} sm={5} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 6 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{color: '#fff', fontWeight: 800}}>Contactos</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {CONTACTOS.map((item, index) => {
                                            return (
                                                <Grid item container lg={12} sm={12} xs={12}
                                                      sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                                    <Tarjeta_Contactos_Footer item={item}/>
                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={3} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 6 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{color: '#fff', fontWeight: 800}}>Políticas</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {POLITICAS.map((item, index) => {
                                            return (
                                                <Grid item container lg={12} sm={12} xs={12}
                                                      sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                                    <ButtonBase
                                                        onClick={() => abrirPoliticas(item)}
                                                    >
                                                        <Typography sx={{color: '#fff', fontWeight: 300}}>
                                                            {item.nombre}
                                                        </Typography>
                                                    </ButtonBase>

                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={3} sm={3} xs={12}
                              sx={{justifyContent: 'center', marginTop: sCell ? 6 : 4}}>
                            <img src={logo} width={sCell ? '80%' : '100%'} height={'auto'}/>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default Footer

