/**************************************************
 * Nombre:       Tabla_Participantes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {GRADIENTE} from "../../Constantes";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import CabeceraListaParticipantes from "./SubComponetes/CabeceraListaParticipantes";
import LineaListaParticipantes from "./SubComponetes/LineaListaParticipantes";
import {useEffect, useState} from "react";

const Tabla_Participantes = ({item}) => {
    const {sCell} = useResponsive()
    const [participaciones, setParticipaciones] = useState([])
    const [participacionesMostrar, setParticipacionesMostrar] = useState([])
    const [index, setIndex] = useState(0)

    const verMas = (part) => {


        let num = index
        let limite = num + 5
        if (limite > part.length) {
            limite = part.length
        }

        for (let i = num; i < limite; i++) {
            setParticipacionesMostrar((arr) => arr.concat(part[i]))
        }

        setIndex(limite)


    }

    const comparar = (a, b) => {
        if (a.fecha > b.fecha) {
            return -1;
        }
        if (a.fecha < b.fecha) {
            return 1;
        }
        // a debe ser igual b
        return 0;
    }


    useEffect(() => {

        if (item && item.participaciones) {
            let parts = item.participaciones
            let partsOrdenadas = parts.sort(comparar)
            setParticipaciones(partsOrdenadas)
            verMas(partsOrdenadas)

        }

    }, [item]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={8} sm={12} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: 40,
                                lineHeight: 1,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Lista de Participantes
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 1}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 300,
                                fontSize: 16,
                                lineHeight: 1.4,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Aquí está la lista de todos los participantes activos en esta subasta, todos ellos
                                desean cumplir el sueño de tener lo último en tecnología.
                            </Typography>
                        </Grid>

                        {!sCell &&
                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                <CabeceraListaParticipantes/>
                            </Grid>
                        }

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {participacionesMostrar.map((it, index) => {
                                    return (
                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <LineaListaParticipantes item={it} producto={item}/>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                            <Button
                                onClick={() => verMas(participaciones)}
                                fullWidth={sCell} sx={{background: GRADIENTE}}>Ver mas</Button>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default Tabla_Participantes
