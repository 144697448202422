/**************************************************
 * Nombre:       EconomiaColaborativa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import logo from '../../Recursos/logo_color.svg'
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";


const Nosotros_Suenos = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={8} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                    <Typography sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 700,
                                        fontSize: 40,
                                        lineHeight: 1,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        Nuestro sueño
                                    </Typography>
                                </Grid>

                                <Grid item container lg={10} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Typography sx={{
                                        color: theme.palette.secondary.main,
                                        fontWeight: 300,
                                        fontSize: 16,
                                        lineHeight: 1.4,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        Imaginamos un futuro en el que cada persona, sin importar su situación
                                        económica, pueda disfrutar de los mejores equipos tecnológicos a precios
                                        accesibles, gracias a nuestro innovador modelo de subastas. Adicionalmente,
                                        creemos en la construcción de una comunidad y un ecosistema donde la calidad, la
                                        innovación y la accesibilidad se encuentren en perfecta armonía, y donde cada
                                        transacción no solo sea una compra, sino una experiencia memorable, asegurando
                                        un futuro donde la tecnología avanzada sea un bien común, no un lujo. Creemos en
                                        el poder del apalancamiento para transformar vidas, empoderar a nuestros
                                        participantes y construir una comunidad dinámica y conectada.

                                    </Typography>
                                </Grid>


                            </Grid>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-end', marginTop: sCell ? 8 : 0}}>
                            <img src={logo} width={'80%'} height={'auto'}/>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Nosotros_Suenos