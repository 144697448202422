/**************************************************
 * Nombre:       Portada
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {GRADIENTE} from "../../Constantes";
import {theme} from "../../Tema";
import avatar from '../../Temporales/tmp_avatar_medio_cuerpo.png'
import fondo_avatar from '../../Recursos/fondo_avatar_portada.svg'
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Login from "../Dialogos/Dialogo_Login";
import {useContext} from "react";
import {usuarioContext} from "../../App";
import {GavelOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const Portada = () => {
    const {sCell, sTab} = useResponsive()
    const navigate = useNavigate()
    const cData = useContext(usuarioContext)
    const vacio = () => {
    }
    const {Dialogo: DialogoIngresar, abrir: abrirIngresar} = useDialogo({Componente: Dialogo_Login, realizado: vacio})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >



            <DialogoIngresar/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                    >

                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 10}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography style={{
                                        background: GRADIENTE,
                                        webkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        fontSize: sCell ? 52 : 64,
                                        fontWeight: 800,
                                        lineHeight: 1,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>Compras interactivas</Typography>
                                </Grid>

                                <Grid item container lg={10} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Typography style={{
                                        color: theme.palette.primary.main,
                                        fontSize: sCell ? 18 : 16,
                                        fontWeight: 300,
                                        lineHeight: 1.3,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        Productos tecnológicos de alta gama, nuevos, originales y con garantía completa
                                        de la marca. Consíguelos a un excelente precio participando en nuestras subastas
                                        interactivas, respaldadas por la seguridad de la tecnología Blockchain.
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{
                                          justifyContent: sCell ? 'center' : 'flex-start',
                                          marginTop: 6,
                                          marginBottom: 16
                                      }}>


                                    {cData && cData.usuario.nick !== 'Invitado' ?

                                        <Button

                                            startIcon={<GavelOutlined/>}
                                            onClick={() => navigate('/Participaciones')}
                                            sx={{background: GRADIENTE, px: 8}}>Ver mis participaciones</Button>


                                        :

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={2}
                                        >

                                            <Grid item container lg={8} sm={4} xs={12}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <Button
                                                    fullWidth
                                                    onClick={() => abrirIngresar()}
                                                    sx={{background: GRADIENTE, px: 8}}>Ingresar o Registrarse</Button>
                                            </Grid>


                                        </Grid>

                                    }
                                </Grid>


                            </Grid>

                        </Grid>

                        {!sTab &&
                            <Grid item container lg={6} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: sCell ? -8 : 12}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: 'flex-start', marginBottom: sCell ? 0 : -20}}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                        >

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                                <Typography
                                                    sx={{
                                                        color: theme.palette.primary.main,
                                                        fontSize: 22,
                                                        fontWeight: 800
                                                    }}
                                                >Obtén</Typography>
                                            </Grid>

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <Typography sx={{
                                                    background: GRADIENTE,
                                                    color: '#fff',
                                                    pl: 2,
                                                    py: 1,
                                                    pr: sCell ? 2 : 10,
                                                    borderRadius: 2,
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    width: sCell ? '100%' : 'auto',
                                                    textAlign: sCell ? 'center' : 'left'
                                                }}>Lo ultimo en tecnologia</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{
                                              justifyContent: 'center',
                                              background: `url(${fondo_avatar})`,
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: 'center',
                                              backgroundSize: 'cover',
                                              marginTop: sCell ? 4 : -8
                                          }}>

                                        <img src={avatar} width={'80%'} height={'auto'}/>

                                    </Grid>


                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default Portada