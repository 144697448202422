/**************************************************
 * Nombre:       TituloDatosCertificadoSubasta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Font, StyleSheet, Text, View} from "@react-pdf/renderer";
import {React} from "react";
import f700 from "../Recursos/Geologica-Bold.ttf";
import f400 from "../Recursos/Geologica-Medium.ttf";
import TituloTexto from "./TituloTexto";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";


const TituloDatosCertificadoSubasta = ({participacion}) => {
    Font.register({
        family: 'Geologica', fonts: [
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f400,
                fontWeight: 400
            }
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#EFEFEF',
            padding: 0
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        textoT: {
            fontSize: 16,
            fontFamily: 'Geologica',
            color: '#00000090',
            fontWeight: 700
        },
        texto: {
            fontSize: 10,
            fontFamily: 'Geologica',
            color: '#00000090',
            fontWeight: 400
        }

    });


    return (
        <View style={styles.git}>
            <View style={styles.gitc}>

                <View style={{...styles.git, justifyContent: 'center'}}>
                    <Text style={{...styles.textoT, width: '100%', textAlign: 'center'}}>CERTIFICADO DE
                        PARTICIPACION</Text>
                </View>

                <View style={{...styles.git, justifyContent: 'center'}}>
                    <Text style={{...styles.texto, width: '100%', textAlign: 'center'}}>
                        Tu viaje con DREAM FREE comienza ahora
                        ¡Estamos emocionados de tenerte con nosotros!
                        Y crear un futuro entre todos

                    </Text>
                </View>

                <View style={{marginTop: 30}}/>

                <View style={styles.git}>
                    <TituloTexto titulo={'ID Subasta:'} texto={participacion.idSubasta} difiere={true}/>
                </View>

                <View style={styles.git}>
                    <TituloTexto titulo={'Nombre del Producto:'} texto={participacion.producto}/>
                </View>

                <View style={styles.git}>
                    <TituloTexto titulo={'Serial del Producto:'} texto={participacion.valoresContrato.serial}/>
                </View>

                <View style={styles.git}>
                    <TituloTexto titulo={'Valor Comercial:'}
                                 texto={formatoMoneda(participacion.valoresContrato.valorComercial)}/>
                </View>


            </View>
        </View>
    )

}
export default TituloDatosCertificadoSubasta