/**************************************************
 * Nombre:       Detalle_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import Portada_Detalle_Producto from "../Componentes/Portada_Detalle_Producto";
import Participar_Subasta from "../Componentes/Participar_Subasta";
import Tabla_Participantes from "../Componentes/Tabla_Participantes";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import PorQueTickets from "../Componentes/PorQueTickets";

const Detalle_Producto = () => {
    const {sCell} = useResponsive()
    const parms = useParams()
    const [producto, setProducto] = useState({})


    useEffect(() => {

        //  irArriba()


        if (parms && parms.id) {
            //setProducto(PRODUCTOSDEFAULT[parms.id])
            obtenerDoc('subastas', parms.id).then((dox) => {
                if (dox.res) {
                    setProducto(dox.data)
                }
            })
        }

    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Portada_Detalle_Producto item={producto}/>
            </Grid>

            <Grid item container id={'participa'} lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Participar_Subasta item={producto}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 14}}>
                <PorQueTickets/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 14}}>
                <Tabla_Participantes item={producto}/>
            </Grid>


        </Grid>
    )

}
export default Detalle_Producto