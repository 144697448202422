/**************************************************
 * Nombre:       ProductosActivos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {GRADIENTE, SECCIONES} from "../../Constantes";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import Tarjeta_Producto from "../Tarjetas/Tarjeta_Producto";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";
import useRecuperarColleccionListeningToda from "../../Servicios/BD/useRecuperarColleccionListeningToda";
import {useState} from "react";
import {where} from "firebase/firestore";

const ProductosActivos = () => {
    const {masSM, sCell, sTab} = useResponsive()
    const [wheres, setWheres] = useState([where('estado', '==', 'Activa')])
    const {data} = useRecuperarColleccionListeningToda({col: 'subastas', orden: 'fecha', wheres: wheres})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{background: GRADIENTE}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, pt: 6, pb: 4}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        {!sCell &&
                            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{color: '#fff', fontWeight: 300}}>
                                    Puedes adquirir estos productos con nosotros de la manera tradicional (y aburrida) o
                                    disfrutar de un excelente descuento participando en nuestras emocionantes subastas.
                                </Typography>
                            </Grid>
                        }


                        <Grid item container lg={6} sm={5} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-end'}}>
                            <Typography sx={{
                                color: '#fff',
                                fontSize: sCell ? 32 : 40,
                                fontWeight: 800,
                                lineHeight: 1.1,
                                textAlign: sCell ? 'center' : 'right'
                            }}>
                                Subastas activas
                            </Typography>
                        </Grid>

                        {sCell &&
                            <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                                <Typography sx={{color: '#fff', fontWeight: 300, textAlign: 'center'}}>
                                    Puedes adquirir estos productos con nosotros de la manera tradicional (y aburrida) o
                                    disfrutar de un excelente descuento participando en nuestras emocionantes subastas.
                                </Typography>
                            </Grid>
                        }

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 7 : 4}}>

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={sCell ? 2 : 0}
                            >

                                <Grid item container lg={3} sm={4} xs={6} sx={{justifyContent: 'flex-start'}}>
                                    <Button
                                        onClick={() => scrolearAComponente(SECCIONES[2])}
                                        sx={{
                                            background: '#ffffff20',
                                            pt: 1,
                                            pb: 1.1,
                                            border: 1,
                                            borderColor: '#ffffff80'
                                        }}>¿Por qué subastas?</Button>
                                </Grid>

                                <Grid item container lg={3} sm={4} xs={6} sx={{justifyContent: 'flex-start'}}>
                                    <Button
                                        onClick={() => scrolearAComponente(SECCIONES[3])}
                                        sx={{
                                            background: '#ffffff20',
                                            pt: 1,
                                            pb: 1.1,
                                            border: 1,
                                            borderColor: '#ffffff80'
                                        }}
                                    >¿Cómo funciona?</Button>
                                </Grid>

                                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                                    {/*}  <Button
                                        sx={{
                                            background: '#ffffff20',
                                            pt: 1,
                                            pb: 1.1,
                                            border: 1,
                                            borderColor: '#ffffff80'
                                        }}
                                        endIcon={<ArrowRight/>}>Todos los productos</Button> */}
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>

                            {data.length > 4 ?

                                <Splide aria-label="My Favorite Images Two" style={{width: '100%'}}
                                        options={{
                                            autoplay: false,
                                            speed: 3000,
                                            rewind: true,
                                            perPage: sCell ? 1 : sTab ? 3 : 4,
                                            arrows: true,
                                            gap: 2,
                                            pagination: true,
                                            drag: 'free',
                                            width: '100%'
                                        }}
                                >
                                    {data.map((item, index) => {
                                        return (
                                            <SplideSlide key={`tr${index}`}>
                                                <Tarjeta_Producto item={item}/>
                                            </SplideSlide>
                                        )
                                    })}


                                </Splide>

                                :

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >

                                    {data.map((item, index) => {
                                        return (
                                            <Grid item container lg={3} sm={4} xs={12}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <Tarjeta_Producto item={item}/>
                                            </Grid>
                                        )
                                    })
                                    }


                                </Grid>


                            }

                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default ProductosActivos

const items = [1, 2, 3, 4, 5, 6, 7]