import axios from "axios";
import {URLAPI} from "../Constantes";

export const obtenerTokenBarrer = () => {
    return new Promise(resolve => {
        axios.get(URLAPI, {})
            .then(function (response) {
                return resolve({res: true, data: response.data})
            })
            .catch(function (error) {
                return resolve({res: false, data: error.message})
            });
    })
}