/**************************************************
 * Nombre:       Dialogo_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Registro from "../../PaginaConstruccion/Formularios/Formulario_Registro";
import {GRADIENTE} from "../../Constantes";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import {funCrearUsuario} from "../../Servicios/Auth/funCrearUsuario";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {getID} from "../../Servicios/BD/useEscucharUsuarioToCorreo";
import Formulario_usuario from "../Formularios/Formulario_usuario";

const Dialogo_Usuario_Editar = ({cerrar, datos}) => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})

    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Realizando Cambios')
            let obj = entidad
            consultarNick(obj)
        })
    }

    const consultarNick = (ent) => {

        if (ent.nick === datos.nick) {
            registarUsuario(ent)
        } else {
            let q = query(collection(fire, 'usuarios'), where('nick', '==', ent.nick))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res && dox.data.length > 0) {
                    alert('Este Nickname ya esta tomado, porfavor ingresa otro')
                    cerrarCargador()
                } else {
                    registarUsuario(ent)
                }
            })
        }


    }

    const registarUsuario = (ent) => {

        guardarDoc('usuarios', ent).then((des) => {
            if (des.res) {
                cerrar()
                cerrarCargador()
                alert('Cambios realizados')
            }
        })

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{px: 2}}
        >

            <Cargador/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_usuario props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4, marginBottom: 2}}>
                <Button
                    onClick={() => guardarDatos()}
                    sx={{px: 8, pt: 1, pb: 1.1, background: GRADIENTE}}>Guardar Cambios</Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Usuario_Editar