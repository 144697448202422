/**************************************************
 * Nombre:       TituloDatosCertificadoSubasta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Font, StyleSheet, Text, View} from "@react-pdf/renderer";
import {React} from "react";
import f700 from "../Recursos/Geologica-Bold.ttf";
import f500 from "../Recursos/Geologica-Regular.ttf";


const TituloTexto = ({texto, titulo, difiere = false}) => {
    Font.register({
        family: 'Geologica', fonts: [
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f500,
                fontWeight: 500
            }
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#EFEFEF',
            padding: 0
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        textoT: {
            fontSize: 10,
            fontFamily: 'Geologica',
            color: '#00000090',
            fontWeight: 700
        },
        texto: {
            fontSize: 10,
            fontFamily: 'Geologica',
            color: '#00000090',
            fontWeight: 500
        }

    });


    return (
        <View style={styles.git}>

            <Text style={{...styles.textoT, width: '32%',}}>
                {titulo}
            </Text>

            <Text style={{...styles.texto, width: '68%', fontWeight: difiere ? 700 : 500}}>
                {texto}
            </Text>

        </View>
    )

}
export default TituloTexto