/**************************************************
 * Nombre:       BarraPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Grid, IconButton, Typography} from "@mui/material";
import CuadroAvatar from "../Componentes/SubComponetes/CuadroAvatar";
import {theme} from "../../Tema";
import {GRADIENTE} from "../../Constantes";
import IndicadorHover from "../Componentes/SubComponetes/IndicadorHover";
import {MenuOpen} from "@mui/icons-material";

const BarraPc = ({usuario, numInvitados, abrir, setOpen, numTickets = 0, numMedallas = 0, numParticipaciones = 0}) => {

    const getNick = (usu) => {

        if (usu.tipo === 'Invitado') {
            return `Invitado #${numInvitados}`
        } else {
            return usu.nick
        }

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', py: 3}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: 12,
                            px: 3,
                            py: 1.5,
                            boxShadow: '0px 0px 20px 5px #0000000A'
                        }}
                    >

                        <Grid item container lg={3} sm={3} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}

                            >

                                <Grid item container lg={3} sm={3} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <ButtonBase sx={{borderRadius: 4}}
                                                onClick={() => setOpen(true)}
                                    >
                                        <CuadroAvatar item={{img: usuario.imgCuadrado}}/>
                                    </ButtonBase>
                                </Grid>

                                <Grid item container lg={9} sm={9} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <Typography sx={{
                                                color: theme.palette.secondary.main,
                                                fontWeight: 800,
                                                fontSize: 24,
                                                lineHeight: 1.1
                                            }}>{getNick(usuario)}</Typography>
                                        </Grid>

                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start', marginTop: -0.5}}>
                                            <Typography sx={{
                                                color: theme.palette.secondary.main,
                                                fontWeight: 300,
                                                fontSize: 16
                                            }}>{usuario.tipo}</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>


                            </Grid>

                        </Grid>

                        <Grid item container lg={9} sm={9} xs={12} sx={{justifyContent: 'flex-end'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start', pr: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >

                                        <Grid item container lg={3} sm={3} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <IndicadorHover nombre={'Tickets'} numero={numTickets}/>
                                        </Grid>
                                        <Grid item container lg={1} sm={1} xs={1}
                                              sx={{justifyContent: 'center', marginLeft: -0.5}}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                sx={{
                                                    borderLeft: 1,
                                                    height: 40,
                                                    ml: 1.2,
                                                    borderColor: theme.palette.primary.main
                                                }}
                                            />


                                        </Grid>

                                        <Grid item container lg={3} sm={3} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <IndicadorHover nombre={'Medallas'} numero={numMedallas}/>
                                        </Grid>
                                        <Grid item container lg={1} sm={1} xs={1}
                                              sx={{justifyContent: 'center', marginLeft: -0.5}}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                sx={{
                                                    borderLeft: 1,
                                                    height: 40,
                                                    ml: 1.2,
                                                    borderColor: theme.palette.primary.main
                                                }}
                                            />


                                        </Grid>

                                        <Grid item container lg={3} sm={3} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <IndicadorHover nombre={'Participa...'} numero={numParticipaciones}/>
                                        </Grid>

                                    </Grid>
                                </Grid>


                                <Grid item container lg={usuario.tipo === 'Invitado' ? 2 : 1}
                                      sm={usuario.tipo === 'Invitado' ? 2 : 1} xs={12}
                                      sx={{justifyContent: 'flex-end'}}>
                                    {usuario.tipo === 'Invitado' ?
                                        <Button
                                            onClick={() => abrir()}
                                            sx={{background: GRADIENTE}}>Ingresar</Button>
                                        :
                                        <IconButton
                                            onClick={() => setOpen(true)}
                                        >
                                            <MenuOpen color={'primary'} sx={{width: 35, height: 35}}/>
                                        </IconButton>

                                    }
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default BarraPc