/**************************************************
 * Nombre:       ListaGandores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {GRADIENTE} from "../../Constantes";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import CabeceraParticipaciones from "./SubComponetes/CabeceraParticipaciones";
import {React, useContext, useEffect, useState} from "react";
import {where} from "firebase/firestore";
import {usuarioContext} from "../../App";
import useRecuperarColleccionListeningToda from "../../Servicios/BD/useRecuperarColleccionListeningToda";
import LineaListaParticipaciones from "./SubComponetes/LineaListaParticipaciones";

const ListaParticipacionesGandas = ({perfil}) => {
    const {sCell, masSM} = useResponsive()
    const cData = useContext(usuarioContext)
    const [wheres, setWheres] = useState([where('idUsuario', '==', 'none')])
    const {data} = useRecuperarColleccionListeningToda({col: 'participaciones', wheres: wheres, direccion: 'desc'})

    useEffect(() => {

        if (perfil && perfil.id) {
            setWheres([where('idUsuario', '==', perfil.id), where('ganadora', '==', true)])
        }


    }, [perfil]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >


                        {masSM &&
                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                <CabeceraParticipaciones/>
                            </Grid>
                        }

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {data.map((it, index) => {
                                    return (
                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <LineaListaParticipaciones item={it}/>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default ListaParticipacionesGandas

const ganadores = [1, 2, 3, 4]