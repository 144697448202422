/**************************************************
 * Nombre:       TituloDatosCertificadoSubasta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Font, StyleSheet, View} from "@react-pdf/renderer";
import {React} from "react";
import f700 from "../Recursos/Geologica-Bold.ttf";
import TituloTexto from "./TituloTexto";
import ParticipacionesTickets from "./ParticipacionesTickets";


const ParticipacionesUsuario = ({qr, participacion, tikets}) => {
    Font.register({
        family: 'Geologica', fonts: [
            {
                src: f700,
                fontWeight: 700
            }
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#EFEFEF',
            padding: 0
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        textoT: {
            fontSize: 12,
            fontFamily: 'Geologica',
            color: '#7F7F7F',
            fontWeight: 700
        },
        texto: {
            fontSize: 40,
            fontFamily: 'Geologica',
            color: '#7F7F7F',
            fontWeight: 500
        }

    });


    return (
        <View style={styles.git}>
            <View style={styles.gitc}>


                <View style={styles.git}>
                    <TituloTexto titulo={'Nick de usuario:'} texto={participacion.nick} difiere={true}/>
                </View>

                <View style={styles.git}>
                    <TituloTexto titulo={'Participaciones de usuario:'}
                                 texto={participacion.ticketsParticipando.length}/>
                </View>

                <View style={{marginTop: 0}}/>

                <View style={styles.git}>

                    <View style={styles.gitc}>


                        {tikets.map((it, index) => {
                            return (
                                <View style={{...styles.git, marginTop: 14, width: '100%'}}>

                                    <View style={styles.gitc}>

                                        <ParticipacionesTickets item={it} img={qr}/>

                                    </View>
                                </View>
                            )
                        })}

                    </View>
                </View>


            </View>
        </View>
    )

}
export default ParticipacionesUsuario


const items = [1, 2, 3, 4, 5, 6, 7, 8]