/**************************************************
 * Nombre:       Participar_Subasta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import IngresoPuja from "./SubComponetes/IngresoPuja";
import {React, useContext, useEffect, useState} from "react";
import {obtenerValoresDisponibles} from "../../Utilidades/obtenerValoresDisponibles";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {usuarioContext} from "../../App";
import {prepararPujas} from "../../Utilidades/prepararPujas";
import {agregarMultiplesItemArrayDoc} from "../../Servicios/BD/agregarMultiplesItemArrayDoc";
import {LoadingButton} from "@mui/lab";
import {GRADIENTE} from "../../Constantes";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Login from "../Dialogos/Dialogo_Login";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {obtenerPosibilidades} from "../../Utilidades/obtenerPosibilidades";
import TablaPujas from "./TablaPujas";
import Certificado from "./SubComponetes/Certificado";
import {where} from "firebase/firestore";
import Dialogo_Comprar from "../Dialogos/Dialogo_Comprar";
import {obtenerColleccionWhere} from "../../Servicios/BD/obtenerColleccionWhere";
import {actualizarMultiplesDocs} from "../../Servicios/BD/actualizarMultiplesDocs";
import {adaptador_participaciones} from "../Adaptadores/adaptador_participaciones";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {enviarNotificacionAdmins} from "../../Utilidades/enviarNotificacionAdmins";
import ParrafoVerContrato from "./SubComponetes/ParrafoVerContrato";
import {useNavigate} from "react-router-dom";


const Participar_Subasta = ({item}) => {
    const {sCell} = useResponsive()
    const cData = useContext(usuarioContext)
    const navigate = useNavigate()
    const [valoresDisponibles, setValoresDisponibles] = useState([])
    const [cargando, setCargando] = useState(false)
    const [pujas, setPujas] = useState([])
    const [ticketsDisponibles, setTicketsDispinibles] = useState([])
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Login, realizado: () => {
        }
    })
    const {Dialogo: DialogoComprar, abrir: abrirComprar} = useDialogo({
        Componente: Dialogo_Comprar, realizado: () => {
        }
    })


    const registrarValores = () => {

        if (verificarTicketsDisponibles()) {
            setCargando(true)
            let arr = prepararPujas(pujas, cData.usuario, ticketsDisponibles)

            if (arr.length >= valoresDisponibles.length) {
                enviarNotificacionAdmins({
                    titulo: 'Subasta completa',
                    mensaje: `${item.nombre} a completado el numero de tickets`
                })
            }


            agregarMultiplesItemArrayDoc({
                coleccion: 'subastas',
                id: item.id,
                nombreArray: 'participaciones',
                arrObjs: arr
            }).then((dox) => {
                if (dox.res) {

                    actualizarTickets(arr, item)

                } else {
                    alert(dox.data)
                }
                setCargando(false)
            })
        } else {
            abrirComprar({
                titulo: `${ticketsDisponibles.length} de ${pujas.length} tickets necesarios para continuar`,
                descripcion: ''
            })
        }


    }

    const actualizarTickets = (arr, subasta) => {

        // actualizar - idSubasta - valorEscogido
        let arrParaCambio = []
        let ticketsUsados = []
        for (let i = 0; i < arr.length; i++) {
            let puja = arr[i]
            let ticket = ticketsDisponibles[i]
            ticketsUsados.push({id: ticket.id, valor: puja.valor, hash: ticket.hash})
            arrParaCambio.push({
                id: ticket.id,
                datos: {
                    valor: puja.valor,
                    idSubasta: subasta.idSubasta,
                    idSubastaInterno: subasta.id
                }
            })

        }

        actualizarMultiplesDocs({col: 'tickets', arrIdsDatos: arrParaCambio}).then((dox) => {
            if (dox.res) {
                guardarParticipacion(subasta, ticketsUsados)
            }
        })


    }

    const guardarParticipacion = (subasta, tickets) => {
        //
        let participacion = adaptador_participaciones({subasta: subasta, tickets: tickets, usuario: cData.usuario})
        console.log('PARTICIPACION', participacion)
        guardarDoc('participaciones', participacion).then((dox) => {
            if (dox.res) {
                alert('Participaciones realizadas.\nSerás redirigido a la sección \'Mis Participaciones\'.')
                cData.recargar()
                navigate('/Participaciones')

            }
        })
    }
    const ingresarPuja = (e) => {

        if (pujas.findIndex((it) => it === e) === -1) {
            setPujas((arr) => arr.concat(e))
        }


    }
    const verificarTicketsDisponibles = () => {
        return ticketsDisponibles.length >= pujas.length
    }
    const obtenerTicketsDisponibles = (usuario) => {


        let wheres = [
            where('idUsuario', '==', usuario.id),
            where('idSubasta', '==', 'none')]


        obtenerColleccionWhere({col: 'tickets', wheres: wheres}).then((dox) => {
            console.log('tickDisponibles', dox)
            if (dox.res) {
                setTicketsDispinibles(dox.data)
            } else {
                setTicketsDispinibles([])
            }
        })


    }
    const borrarPuja = (index) => {

        let arr = []
        for (let i = 0; i < pujas.length; i++) {
            if (i !== index) {
                arr.push(pujas[i])
            }
        }

        setPujas(arr)

    }

    useEffect(() => {

        if (item && item.idSubasta) {

            setValoresDisponibles([])
            obtenerDoc('subastas', item.id).then((dox) => {
                if (dox.res) {
                    setValoresDisponibles(obtenerValoresDisponibles(dox.data))
                }
            })


        }


    }, [item]);

    useEffect(() => {

        if (cData.usuario && cData.usuario.id) {
            obtenerTicketsDisponibles(cData.usuario)
        }

    }, [cData]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Dialogo/>

                <DialogoComprar/>

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"

                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{color: theme.palette.primary.main, fontSize: 32, fontWeight: 700}}>Participar
                                en subasta</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <ParrafoVerContrato item={item}/>
                        </Grid>


                        <Grid item container lg={7} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 6}}>
                            {(item && parseInt(item.numTickets)) <= (item.participaciones && item.participaciones.length) ?
                                <Typography sx={{
                                    fontWeight: 600,
                                    fontSize: 42,
                                    color: theme.palette.primary.main,
                                    textAlign: 'center',
                                    width: '100%',
                                    lineHeight: 1.1
                                }}>Subasta terminada</Typography>
                                :
                                <IngresoPuja valoresDisponibles={valoresDisponibles} ingresarPuja={ingresarPuja}/>
                            }

                        </Grid>

                        {pujas.length > 0 &&
                            <>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'center', marginTop: 2, marginBottom: 8}}>
                                    <Typography sx={{
                                        fontSize: 24,
                                        fontWeight: 800,
                                        color: theme.palette.primary.main,
                                        textAlign: 'center'
                                    }}>{`${obtenerPosibilidades({
                                        numPujas: pujas.length,
                                        numTickets: item.numTickets
                                    })}% posibilidades de descubrir el precio`}</Typography>
                                </Grid>


                                <Grid item container lg={10} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginBottom: 8}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-end"
                                        spacing={8}
                                    >

                                        <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <TablaPujas pujas={pujas} borrarPuja={borrarPuja}/>
                                        </Grid>


                                        <Grid item container lg={6} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                            <Certificado pujas={pujas} item={item}/>
                                        </Grid>


                                    </Grid>
                                </Grid>


                                <Grid item container lg={6} sm={12} xs={12}
                                      sx={{justifyContent: 'center', marginTop: 0, pl: sCell ? 0 : 8}}>

                                    {cData && cData.usuario.nick === 'Invitado' ?

                                        <Button sx={{background: GRADIENTE}}
                                                onClick={() => abrir()}
                                        >
                                            Ingresar y participar
                                        </Button>

                                        :

                                        <LoadingButton
                                            sx={{background: GRADIENTE, fontSize: 18, px: 6, fontWeight: 600}}
                                            loading={cargando}
                                            variant={'contained'}
                                            onClick={() => registrarValores()}
                                        >{`Confirmar Participacion ${pujas.length} Tickets`}</LoadingButton>
                                    }


                                </Grid>


                            </>

                        }
                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Participar_Subasta