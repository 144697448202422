/**************************************************
 * Nombre:       CabeceraLista
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {
    CategoryOutlined,
    CommentOutlined,
    EventAvailableOutlined,
    Flag,
    FlagOutlined,
    Inventory,
    Inventory2,
    Inventory2Outlined,
    InventoryOutlined,
    LocalActivityOutlined,
    MonetizationOnOutlined, PercentOutlined,
    PermIdentityOutlined, Segment, SegmentOutlined, ThumbUp, ThumbUpOutlined, Upload
} from "@mui/icons-material";
import {theme} from "../../../Tema";

const CabeceraRetos = () => {


    const IconoTitulo = ({Icono, titulo}) => {
        return (
            <Typography sx={{color: theme.palette.secondary.main}}>
                    <span style={{marginBottom: -10, marginTop: 4, paddingRight: 6}}>
                        <Icono color={'secondary'} sx={{marginBottom: -0.7, fill: ''}}/>
                    </span>
                {titulo}
            </Typography>
        )
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
                backgroundColor: '#2626ED10',
                px: 6,
                py: 2,
                borderRadius: 30
            }}
        >

            <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <IconoTitulo titulo={'Reto'} Icono={ThumbUpOutlined}/>
            </Grid>

            <Grid item container lg={7} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <IconoTitulo titulo={'Descripcion'} Icono={SegmentOutlined}/>
            </Grid>


            <Grid item container lg={2} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IconoTitulo titulo={'Estado'} Icono={Upload}/>
            </Grid>


        </Grid>
    )

}
export default CabeceraRetos