/**************************************************
 * Nombre:       PorQueTickets
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";

const PorQueTickets = () => {
    const {sCell, masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: sCell ? 32 : 40,
                                lineHeight: 1,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                ¿Porque Tickets?
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 300,
                                fontSize: 16,
                                lineHeight: 1.4,
                                textAlign: 'left'
                            }}>
                                El Ticket de Subasta es tu llave de acceso para participar en subastas de productos
                                tecnológicos
                                premium a través de nuestra plataforma. Cada ticket te permite hacer una puja por
                                productos de
                                alto valor a una fracción de su precio de mercado, ofreciendo la posibilidad de pagar
                                entre el
                                10% y el 14% del valor total del producto.
                                <br/>
                                <br/>
                                Fácil de usar: El sistema de tickets es simple y transparente, facilitando la
                                participación en
                                las subastas.
                                <br/>
                                Recompensas adicionales: No solo puedes ganar productos, sino que también puedes
                                acumular
                                medallas y obtener premios adicionales.

                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default PorQueTickets