/**************************************************
 * Nombre:       PerfilAvatar
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";

const PerfilAvatar = ({usuario}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{
                    color: '#fff',
                    fontWeight: 800,
                    fontSize: 20,
                    lineHeight: 1.1
                }}>{usuario.nick}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: -0.5}}>
                <Typography sx={{
                    color: '#fff',
                    fontWeight: 300,
                    fontSize: 16
                }}>{usuario.tipo}</Typography>
            </Grid>
        </Grid>
    )

}
export default PerfilAvatar