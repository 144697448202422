/**************************************************
 * Nombre:       Documentos_Legales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {CONTACTOS, GRADIENTE, LINKMAP, LINKWASS} from "../../Constantes";
import Tarjeta_Contactos_Footer from "../../Pagina/Tarjetas/Tarjeta_Contactos_Footer";
import {irUrl} from "../../Utilidades/irUrl";
import {Map, WhatsApp} from "@mui/icons-material";

const Contactos_Construccion = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"

                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: 40,
                                lineHeight: 1,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Ubicación y Contacto
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'center', marginTop: 4}}>
                            <Typography sx={{
                                color: theme.palette.secondary.main,
                                fontWeight: 300,
                                fontSize: 16,
                                lineHeight: 1.4,
                                textAlign: 'center',
                                width: '50%'
                            }}>
                                Contamos con una oficina donde realizamos todas nuestras operaciones y estaremos
                                encantados de atenderte también de manera presencial.
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 10}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={4}
                            >

                                <Grid item container lg={6} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent={sCell ? 'center' : "flex-start"}
                                        alignItems="flex-start"
                                    >


                                        {CONTACTOS.map((item, index) => {
                                            return (
                                                <Grid item container lg={12} sm={12} xs={12}
                                                      sx={{
                                                          justifyContent: sCell ? 'center' : 'flex-start',
                                                          marginTop: 1
                                                      }}>
                                                    <Tarjeta_Contactos_Footer item={item} difiere={true}/>
                                                </Grid>
                                            )
                                        })}


                                        <Grid item container lg={5} sm={12} xs={12}
                                              sx={{
                                                  justifyContent: sCell ? 'center' : 'flex-start',
                                                  marginTop: 2
                                              }}>
                                            <Button

                                                onClick={() => irUrl(LINKWASS)}
                                                startIcon={<WhatsApp/>}
                                                sx={{background: GRADIENTE, px: 7}}>WhatsApp</Button>
                                        </Grid>

                                        <Grid item container lg={5} sm={12} xs={12}
                                              sx={{
                                                  justifyContent: sCell ? 'center' : 'flex-start',
                                                  marginTop: 2,
                                                  pl: sCell ? 0 : 2
                                              }}>
                                            <Button
                                                startIcon={<Map/>}
                                                onClick={() => irUrl(LINKMAP)}
                                                variant={'outlined'}>Dirigeme</Button>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item container lg={6} sm={12} xs={12}
                                      sx={{justifyContent: 'center', marginTop: sCell ? 4 : 0}}>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7235.421415903582!2d-74.09100109355975!3d4.690606042604108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b2458e0260d%3A0xe11d51a73cd11801!2sCentro%20Comercial%20Plaza%2080!5e0!3m2!1ses-419!2sco!4v1721150050363!5m2!1ses-419!2sco"
                                        width="600" height="300" style={{border: 0}} allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Contactos_Construccion