/**************************************************
 * Nombre:       PortadaPerfil
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {useContext} from "react";
import {usuarioContext} from "../../App";
import {GRADIENTE} from "../../Constantes";
import fondo_perfil from "../../Recursos/fondo_perfil.svg";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {Edit} from "@mui/icons-material";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Usuario_Editar from "../Dialogos/Dialogo_Usuario_Editar";

const PortadaPerfil = ({perfil}) => {
    const usuario = useContext(usuarioContext).usuario
    const {sCell} = useResponsive()
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Usuario_Editar, titulo: 'Editando Usuario'})


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{
                justifyContent: 'center',
                backgroundImage: `url(${fondo_perfil})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                marginTop: -3
            }}>


                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3}}
                >
                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{
                              justifyContent: 'flex-start',
                              marginTop: 2
                          }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"

                        >


                            <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >

                                    <Grid item container lg={3} sm={0} xs={12} sx={{justifyContent: 'flex-start'}}>

                                    </Grid>

                                    <Grid item container lg={6} sm={7} xs={12}
                                          sx={{justifyContent: 'center', marginTop: sCell ? 4 : 8}}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                        >
                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: 'flex-start', marginBottom: sCell ? 0 : -20}}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                >

                                                    <Grid item container lg={12} sm={12} xs={12}
                                                          sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 16,
                                                                fontWeight: 800
                                                            }}
                                                        >{perfil.tipo}</Typography>
                                                    </Grid>

                                                    <Grid item container lg={6} sm={6} xs={12}
                                                          sx={{
                                                              justifyContent: 'flex-start',
                                                              background: GRADIENTE,
                                                              borderRadius: 2
                                                          }}>
                                                        <Typography sx={{
                                                            color: '#fff',
                                                            pl: 2,
                                                            py: 1,
                                                            pr: sCell ? 2 : 10,
                                                            borderRadius: 2,
                                                            fontSize: 22,
                                                            fontWeight: 600,
                                                            width: sCell ? '100%' : 'auto',
                                                            textAlign: sCell ? 'center' : 'left'
                                                        }}>{perfil.nick}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{
                                                      justifyContent: 'center',

                                                      marginTop: sCell ? 0 : -6
                                                  }}>

                                                <img src={perfil.imgRectangular} width={'80%'} height={'auto'}/>

                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid item container lg={3} sm={5} xs={12}
                                          sx={{justifyContent: 'flex-start', marginTop: sCell ? 0 : 4}}>

                                        {usuario.perfil &&

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >

                                                <Grid item container lg={12} sm={12} xs={12} sx={{
                                                    justifyContent: 'flex-start',
                                                    backgroundColor: sCell ? '#fff' : '#ffffff40',
                                                    backdropFilter: 'blur(10px)',
                                                    borderRadius: 4,
                                                    boxShadow: '3.32px 3.32px 12.16px 0px #0000001A',
                                                    p: 3,
                                                    border: 1,
                                                    borderColor: '#fff',


                                                }}>
                                                    <Typography>
                                                        {perfil.perfil}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item container lg={10} sm={12} xs={12}
                                  sx={{
                                      justifyContent: sCell ? 'center' : 'flex-end',
                                      marginTop: sCell ? 3 : -10,
                                      marginBottom: sCell ? 3 : 0,
                                      zIndex: 2
                                  }}>
                                {perfil && usuario.id === perfil.id &&
                                    <Button
                                        onClick={() => abrir(usuario)}
                                        startIcon={<Edit/>}>Editar</Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    )

}
export default PortadaPerfil