import ic_telefono_blanco from './Recursos/ic_telefono_blanco.svg'
import ic_mail_blanco from './Recursos/ic_mail_blanco.svg'
import ic_location_blanco from './Recursos/ic_location_blanco.svg'
import ic_telefono from './Recursos/ic_telefono.svg'
import ic_mail from './Recursos/ic_mail.svg'
import ic_location from './Recursos/ic_location.svg'
import ic_documento from './Recursos/ic_documento.svg'
import logo_redondo from './Recursos/logo_redondo_blanco.svg'
import ip_uno from './Temporales/ip_principal.png'
import ip_dos from './Temporales/ip_dos.png'
import ip_tres from './Temporales/ip_tres.png'
import ip_cuatro from './Temporales/ip_cuatro.png'
import mac_uno from './Temporales/mac_uno.png'
import mac_dos from './Temporales/mac_dos.png'
import mac_tres from './Temporales/mac_tres.png'
import mac_cuatro from './Temporales/mac_cuatro.png'
import pat_uno from './Temporales/pat_uno.png'
import pat_dos from './Temporales/pat_dos.png'
import pat_tres from './Temporales/pat_tres.png'
import pat_cuatro from './Temporales/pat_cuatro.png'

export const ESTADOSPOTENCIALES = ['Nuevo', 'Contactado', 'Archivado']
export const GRADIENTE = 'linear-gradient(90deg, #2626ED 0%, #7D50F5 100%)'

export const SECCIONES = ['Home', 'Subastas', 'Nosotros', '¿Por qué subastas?', '¿Cómo funciona?']


export const LINKWASS = 'https://wa.me/573223666724'

export const LINKMAP = "https://www.google.com/maps/dir/?api=1&destination=4.693169458514678,-74.08591741359997,190&dir_action=navigate"

export const CONTACTOS = [
    {

        img: ic_telefono,
        imgblanco: ic_telefono_blanco,
        largo: 18,
        nombre: '+57 322 366 6724',
        // url: LINKWASS
    },
    {
        img: ic_mail,
        imgblanco: ic_mail_blanco,
        largo: 18,
        nombre: 'info@dreamfree.tech'
    },
    {
        img: ic_location,
        imgblanco: ic_location_blanco,
        largo: 14,
        nombre: 'Carrera 70c # 80-48 Centro comercial Plaza 80 - Bogotá D.C.'
    }
]

export const DOCUMENTOS = [
    {
        img: ic_documento,
        nombre: 'Camara de Comercio',
        imgs: [
            'https://dreamfree.tech/recursos/pages-to-jpg-0001.jpg',
            'https://dreamfree.tech/recursos/pages-to-jpg-0002.jpg',
            'https://dreamfree.tech/recursos/pages-to-jpg-0003.jpg',
            'https://dreamfree.tech/recursos/pages-to-jpg-0004.jpg',
        ]
    },
    /* {
         img: ic_check,
         nombre: 'Permiso de funcionamiento',
         imgs: []
     },*/
]


export const PERFILINVITADO = {
    img: logo_redondo,
    nick: "Invitado",
    tipo: "Invitado"
}


export const PRODUCTOSDEFAULT = [
    {
        id: 'IP0001',
        img: ip_uno,
        imgs: [ip_dos, ip_tres, ip_cuatro],
        nombre: 'iPhone 15 Pro 128 GB 5G Titanio Azul',
        descripcion: 'Sistema Operativo iOS 17, actualizable a iOS 17.0.3, Dimensiones 146,6 x 70,6 x 8,3 mm, Pantalla LTPO Super Retina XDR OLED, 120Hz, HDR10, Dolby Vision, 1000 nits (tipo), 2000 nits (HBM), Procesador Apple A17 Pro (3 nm), Memoria Interna Interna: 128GB - 8GB RAM, Camara Principal 48 MP, f/1,8, 24 mm (ancho), 1/1,28″, 1,22 µm, PDAF de doble píxel, OIS de desplazamiento del sensor ',
        valor: 5200000,
        url: 'https://www.apple.com/co/iphone-15-pro/?&mtid=20925i6642868&aosid=p238&mnid=sHM8iDGF6-dc_mtid_20925i6642868_pcrid_673808741891_pgrid_160252463264_pexid__&cid=wwa-co-kwgo-iphone-slid--Brand-iPhone15Pro-Announce-'
    },
    {
        id: 'IP0002',
        img: mac_uno,
        imgs: [mac_dos, mac_tres, mac_cuatro],
        nombre: 'Macbook Air 13" MRXV3E/A Chip M3 8RAM',
        descripcion: 'El nuevo MacBook Air M3 lleva el rendimiento a otro nivel. Con un chip M3 aún más rápido, una pantalla Liquid Retina más grande y brillante, y una batería de hasta 18 horas, es el portátil perfecto para todo lo que haces. Rendimiento sin precedentes. El chip M3 ofrece un rendimiento hasta un 20% más rápido que el M2, lo que te permite realizar tareas con mayor fluidez y rapidez. Ya sea que estés editando videos, creando presentaciones o jugando a juegos, el MacBook Air M3 puede con todo.',
        valor: 5900000,
        url: 'https://www.apple.com/co/macbook-air/specs/'
    },
    {
        id: 'IP0002',
        img: pat_uno,
        imgs: [pat_dos, pat_tres, pat_cuatro],
        nombre: 'Patineta Eléctrica/Scooter DUCATI Pro II Evo',
        descripcion: 'Movilízate con el respaldo de una de las marcas más reconocidas a nivel mundial, DUCATI trae para ti la patineta scooter eléctrica Pro II Evo, DUCATI siempre reconocida por su innovación y diseño que implementa en las scooter eléctricas el sistema KERS (ya probado en la formula 1) que básicamente utiliza la energía que produces al frenar convirtiéndola en eléctrica. Adicionalmente puedes hacer que tu vehículo sea inteligente gracias a que tendrás acceso a la aplicación bluetooth Ducati. ¡Pídela en línea y movílízate con Ducati!',
        valor: 6000000,
        url: 'https://www.ducatiurbanemobility.com/electric-mobility/pro-ii-evo/'
    },


]

