/**************************************************
 * Nombre:       CEO_Andres
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import andres from '../../Temporales/tmp_andres.png'
import Tarjeta_Producto_Imagen from "../Tarjetas/Tarjeta_Producto_Imagen";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";

const CEO_Andres = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: theme.palette.primary.main}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={10}
                    >

                        <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', mt: -16, mb: 0, px: 4}}>
                                    <Tarjeta_Producto_Imagen imagen={andres}/>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item container lg={7} sm={7} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{pt: 6, pb: 0}}
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{
                                          justifyContent: sCell ? 'center' : 'flex-start',
                                          marginTop: sCell ? -10 : 0
                                      }}>
                                    <Typography sx={{
                                        color: '#ffff',
                                        fontSize: 32,
                                        fontWeight: 800,
                                        lineHeight: 1.1,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>Andrés Arteaga Sarasty</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 3 : 1}}>
                                    <Typography sx={{
                                        color: '#ffff',
                                        fontSize: 14,
                                        fontWeight: 300,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        Hola, mi nombre es Andrés Arteaga, y soy el Director y Líder de Dream Free, una
                                        empresa que nace de las ganas de hacer que la tecnología premium sea accesible
                                        para todos. Dream Free no es solo una plataforma de subastas; es un proyecto de
                                        vida.
                                        <br/>
                                        <br/>
                                        Dream Free representa mi compromiso con la creación de una comunidad donde la
                                        tecnología de alta calidad no sea un lujo, sino una posibilidad real para todos.
                                        Mi propósito con esta empresa es democratizar el acceso a productos tecnológicos
                                        premium, haciéndolos accesibles mediante un sistema de subastas y
                                        apalancamiento.



                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: -6, marginBottom: 8}}>
                            <Typography sx={{
                                color: '#ffff',
                                fontSize: 14,
                                fontWeight: 300,
                                textAlign: sCell ? 'center' : 'left'
                            }}>

                                <br/>

                                La visión de Dream Free es simple pero poderosa: hacer que los mejores productos
                                tecnológicos estén al alcance de todos. A través de nuestra plataforma, no solo
                                ofrecemos productos, sino oportunidades. Oportunidades para que más personas
                                accedan a la tecnología que puede cambiar sus vidas. En Dream Free, estamos
                                redefiniendo lo que significa ser accesible. No solo estamos vendiendo
                                tecnología; estamos ofreciendo una nueva forma de alcanzar sueños.
                                <br/>
                                <br/>

                                Mi compromiso como líder es guiar esta misión con integridad, pasión y un
                                enfoque constante en el bienestar de nuestra comunidad. Porque en Dream Free,
                                creemos que el éxito es algo que se comparte y se construye juntos.


                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default CEO_Andres