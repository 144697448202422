/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import BarraPc from "./BarraPc";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import BarraCell from "./BarraCell";
import {useContext, useEffect, useState} from "react";
import {obtenerConsecutivo} from "../../Servicios/BD/Consecutivos/obtenerConsecutivo";
import {aumentarConsecutivo} from "../../Servicios/BD/Consecutivos/aumentarConsecutivo";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Login from "../Dialogos/Dialogo_Login";
import MenuVertical from "./MenuVertical";
import {usuarioContext} from "../../App";
import {contarColeccionWheres} from "../../Servicios/BD/Contadores/contarColeccionWheres";
import {where} from "firebase/firestore";
import {theme} from "../../Tema";
import Countdown from 'react-countdown';

const Barra = ({usuario = {}}) => {
    const {masSM, sCell} = useResponsive()
    const [numInvitados, setNumInvitados] = useState(0)
    const [open, setOpen] = useState(false)
    const [numParticipaciones, setNumParticipaciones] = useState(0)
    const fechaFinal = new Date(2024, 12, 30)

    const abrirPerfil = () => {
        setOpen(true)
    }
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Login, realizado: abrirPerfil})

    const cData = useContext(usuarioContext)


    useEffect(() => {


        setTimeout(() => {

            if (usuario.nick === 'Invitado') {
                obtenerConsecutivo({col: 'contadores', id: 'invitados'}).then((dox) => {
                    if (dox.res) {

                        setNumInvitados(dox.data)

                        aumentarConsecutivo({col: 'contadores', id: 'invitados', actual: dox.data}).then((dox) => {
                            if (dox.res) {

                            }
                        })

                    }
                })
            }

        }, 3000)


        if (usuario.id) {
            let w = [where('idUsuario', '==', usuario.id)]
            contarColeccionWheres({coleccion: 'participaciones', wheres: w}).then((dox) => {
                if (dox.res) {
                    setNumParticipaciones(dox.data)
                }
            })
        } else {
            setNumParticipaciones(0)
        }


    }, [usuario]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'center', backgroundColor: theme.palette.primary.main,}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', p: 2, pb: masSM ? 2 : 4}}
                >

                    <Grid item container lg={6} sm={6} xs={8} sx={{justifyContent: 'flex-start',}}>
                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 400}}>Participa sin
                            suscripción! </Typography>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={4} sx={{justifyContent: 'flex-end',}}>
                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 400}}>
                            {!sCell &&
                                'Por tiempo limitado '
                            }


                            <Countdown date={fechaFinal}/>
                        </Typography>
                    </Grid>


                </Grid>
            </Grid>

            {masSM ?
                <BarraPc usuario={usuario} numInvitados={numInvitados} abrir={abrir} setOpen={setOpen}
                         numTickets={cData.numTickets} numMedallas={usuario.numMedallas}
                         numParticipaciones={numParticipaciones}/>
                :
                <BarraCell usuario={usuario} numInvitados={numInvitados} abrir={abrir} setOpen={setOpen}
                           numTickets={cData.numTickets} numMedallas={usuario.numMedallas}
                           numParticipaciones={numParticipaciones}/>
            }

            <MenuVertical open={open} setOpen={setOpen}/>

        </Grid>
    )

}
export default Barra