/**************************************************
 * Nombre:       Seccion_Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {useContext, useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";
import {ArrowBackIos} from "@mui/icons-material";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {theme} from "../../Tema";
import ListaParticipaciones from "../Componentes/ListaParticipaciones";
import {usuarioContext} from "../../App";
import Participaciones_Usuario from "../Componentes/Participaciones_Usuario";
import Tabla_Retos from "../Componentes/Tabla_Retos";
import Lista_Producto_Medallas from "../Componentes/Lista_Producto_Medallas";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Producto_Medallas from "../Dialogos/Dialogo_Producto_Medallas";

const Seccion_Kiosco = () => {
    const {sCell, masSM} = useResponsive()
    const cData = useContext(usuarioContext)
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Producto_Medallas, realizado: () => {
        }
    })

    useEffect(() => {
        irArriba()

    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: sCell ? 32 : 40,
                                lineHeight: 1,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Kiosco de Medallas
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 300,
                                fontSize: 16,
                                lineHeight: 1.4,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Canjea tus medallas por productos físicos y digitales, ten en cuenta que estas medallas
                                las ganaste por completar retos, participar en subastas y referenciar amigos
                            </Typography>
                        </Grid>

                        <Grid item container lg={11} sm={12} xs={11} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Lista_Producto_Medallas abrir={abrir} numMedalles={cData.usuario.numMedallas}/>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 6}}>
                <Tabla_Retos perfil={cData.usuario}/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Kiosco