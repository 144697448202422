/**************************************************
 * Nombre:       Seccion_Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Nosotros_Jamssa from "../Componentes/Nosotros_Jamssa";
import CEO_Andres from "../Componentes/CEO_Andres";
import Nosotros_Suenos from "../Componentes/Nosotros_Suenos";
import {useEffect} from "react";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import Contactos_Construccion from "../../PaginaConstruccion/Componentes/Contactos_Construccion";
import {irArriba} from "../../Utilidades/irArriba";
import Documentos_Legales from "../Componentes/Documentos_Legales";

const Seccion_Nosotros = () => {
    const {sCell, masSM} = useResponsive()

    useEffect(() => {
        irArriba()
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 6}}>
                <Nosotros_Jamssa/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 20}}>
                <CEO_Andres/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 24}}>
                <Nosotros_Suenos/>
            </Grid>

            {/*
            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 22}}>
                <Nuestros_Partners/>
            </Grid>
            */}

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 12, marginBottom: 10}}>
                <Contactos_Construccion/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 10 : 12, marginBottom: 20}}>
                <Documentos_Legales/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Nosotros