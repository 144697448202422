/**************************************************
 * Nombre:       TituloDatosCertificadoSubasta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Font, Link, StyleSheet, Text, View} from "@react-pdf/renderer";
import {React} from "react";
import f700 from "../Recursos/Geologica-Bold.ttf";
import TituloTexto from "./TituloTexto";
import TituloTextoMed from "./TituloTextoMed";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import QrTexto from "./QrTexto";


const ContratoSubasta = ({contrato, participacion, qrHashSubasta, qrContratoString}) => {
    Font.register({
        family: 'Geologica', fonts: [
            {
                src: f700,
                fontWeight: 700
            }
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#EFEFEF',
            padding: 0
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%'

        },
        textoT: {
            fontSize: 12,
            fontFamily: 'Geologica',
            color: '#00000090',
            fontWeight: 700
        },
        texto: {
            fontSize: 10,
            fontFamily: 'Geologica',
            color: '#00000090',
            fontWeight: 500
        }

    });


    return (
        <View style={{...styles.git}}>
            <View style={styles.gitc}>

                <View style={{...styles.git, justifyContent: 'center'}}>
                    <Text style={{...styles.textoT, width: '100%', textAlign: 'center'}}>
                        CONTRATO SUBASTA
                    </Text>
                </View>

                <View style={{marginTop: 30}}/>

                <View style={styles.git}>
                    <TituloTexto titulo={'ID Subasta:'} texto={contrato.idSubasta} difiere={true}/>
                </View>

                <View style={styles.git}>
                    <TituloTexto titulo={'Nombre del Producto:'} texto={contrato.nombre}/>
                </View>

                <View style={styles.git}>
                    <TituloTexto titulo={'Serial del Producto:'} texto={contrato.serial}/>
                </View>

                <View style={styles.git}>
                    <TituloTexto titulo={'Numero de Tickets:'} texto={contrato.numeroTickets}/>
                </View>

                <View style={{marginTop: 20}}/>

                <View style={styles.git}>
                    <View style={{...styles.git, width: '50%'}}>
                        <View style={styles.gitc}>

                            <View style={styles.git}>
                                <TituloTextoMed titulo={'Valor Comercial:'}
                                                texto={formatoMoneda(contrato.valorComercial)}/>
                            </View>

                            <View style={styles.git}>
                                <TituloTextoMed titulo={'Valor Minimo:'} texto={formatoMoneda(contrato.valorMinimo)}/>
                            </View>

                            <View style={styles.git}>
                                <TituloTextoMed titulo={'Valor Beneficiado:'} texto={'$ XXXXXXXXX'}/>
                            </View>

                        </View>

                    </View>

                    <View style={{...styles.git, width: '50%'}}>
                        <View style={styles.gitc}>

                            <View style={styles.git}>
                                <TituloTextoMed titulo={'Fecha de Creacion:'} texto={contrato.fechaCreacion}/>
                            </View>

                            <View style={styles.git}>
                                <TituloTextoMed titulo={'Valor Maximo'} texto={formatoMoneda(contrato.valorMaximo)}/>
                            </View>

                        </View>

                    </View>


                </View>

                <View style={{marginTop: 20}}/>

                <View style={styles.git}>
                    <TituloTexto titulo={'Hash de encriptacion subasta: '}
                                 texto={participacion.hash} difiere={true}/>
                </View>

                <View style={{...styles.git, marginTop: 4}}>
                    <TituloTexto titulo={'Contrato antes de encriptado: '}
                                 texto={''}/>
                </View>

                <View style={{...styles.git, marginTop: 4}}>
                    <Text style={styles.texto}>
                        {participacion.contratoString}
                    </Text>
                </View>

                <View style={{...styles.git, marginTop: 30}}>

                    <View style={{...styles.git, width: '50%'}}>
                        <QrTexto qr={qrHashSubasta} texto={'Hash de encriptacion subasta \nMetodo: SHA512/224'}/>
                    </View>

                    <View style={{...styles.git, width: '50%'}}>
                        <QrTexto qr={qrContratoString} texto={'Contrato antes de encriptado \nContrato en String'}/>
                    </View>

                </View>

                <View style={{...styles.git, marginTop: 20}}>
                    <Text style={styles.texto}>
                        **Los datos con xxxxxxx son datos ocultos, estos se rebelaran cuando se cierre la subasta
                    </Text>
                </View>

                <View style={{...styles.git, marginTop: 4}}>
                    <Text style={styles.texto}>
                        Para la transparencia del valor beneficiado se deja publico el hash (codigo) del contrato.
                        encriptado con los datos
                        necesarios para producir este valor, todos estos datos seran rebelados cuando se complete la
                        totalidad de los tickets de la subasta. Estos datos en modo de cadena (String) encriptados
                        mediante el metodo SHA512/224 proporciona el Hash del contrato (contrato encriptado).
                    </Text>
                </View>

                <View style={{...styles.git, marginTop: 4}}>
                    <Text style={styles.texto}>
                        Hacemos publica la herramienta de encriptacion utilizada para mayor transparencia.
                    </Text>
                </View>

                <View style={{...styles.git, marginTop: 4, marginBottom: -10}}>
                    <Link style={{...styles.texto, textDecoration: 'none'}}>
                        https://emn178.github.io/online-tools/sha512_224.html
                    </Link>
                </View>


            </View>
        </View>
    )

}
export default ContratoSubasta