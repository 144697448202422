/**************************************************
 * Nombre:       Lista_Producto_Medallas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Tarjeta_Producto_Medallas from "../Tarjetas/Tarjeta_Producto_Medallas";
import useRecuperarColleccionListeningToda from "../../Servicios/BD/useRecuperarColleccionListeningToda";


const Lista_Producto_Medallas = ({abrir, numMedalles}) => {
    const {data} = useRecuperarColleccionListeningToda({col: 'productosKiosco', orden: 'fecha'})

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            {data.map((it, index) => {
                return (
                    <Grid item container lg={3} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Tarjeta_Producto_Medallas abrir={abrir} item={it}/>
                    </Grid>
                )
            })}

        </Grid>
    )

}
export default Lista_Producto_Medallas
