/**************************************************
 * Nombre:       CabeceraLista
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import CuadroAvatar from "./CuadroAvatar";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {formatoFecha} from "../../../Utilidades/formatoFecha";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {useNavigate} from "react-router-dom";

const LineaListaParticipantes = ({item, producto}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                px: sCell ? 0 : 6,
                py: 2,
                borderBottom: 1,
                borderColor: theme.palette.secondary.main
            }}
        >

            <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{cursor: 'pointer'}}
                    onClick={() => navigate('/Perfil/' + item.idUsuario)}
                >

                    <Grid item container lg={4} sm={12} xs={3}
                          sx={{justifyContent: 'flex-start', marginBottom: sCell ? 2 : 0}}>
                        <CuadroAvatar item={{img: item.imgCuadrado}}/>
                    </Grid>

                    <Grid item container lg={8} sm={12} xs={9} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{
                                    color: theme.palette.secondary.main,
                                    fontWeight: 800,
                                    fontSize: 20,
                                    lineHeight: 1.1
                                }}>{item.nick}</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: -0.5}}>
                                <Typography sx={{
                                    color: theme.palette.secondary.main,
                                    fontWeight: 300,
                                    fontSize: 16
                                }}>{item.tipo}</Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item container lg={2} sm={12} xs={6} sx={{justifyContent: sCell ? 'flex-start' : 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {formatoFecha(item.fecha)}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={12} xs={6} sx={{justifyContent: sCell ? 'flex-end' : 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {producto.idSubasta}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={12} xs={6} sx={{justifyContent: 'center'}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {producto.nombre}
                </Typography>
            </Grid>

            <Grid item container lg={3} sm={12} xs={6} sx={{justifyContent: 'flex-end', marginTop: sCell ? 2 : 0}}>
                <Typography sx={{color: theme.palette.secondary.main, fontSize: 16, fontWeight: 400}}>
                    {formatoMoneda(item.valor)}
                </Typography>
            </Grid>


        </Grid>
    )

}
export default LineaListaParticipantes