/**************************************************
 * Nombre:       ListaGandores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {GRADIENTE} from "../../Constantes";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {React, useState} from "react";
import LineaListaParticipantesHome from "./SubComponetes/LineaListaParticipantesHome";
import CabeceraListaParticipantesHome from "./SubComponetes/CabeceraListaParticipantesHome";

const ListaGandores = () => {
    const {sCell, masSM} = useResponsive()
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'participaciones',
        wheres: wheres,
        direccion: 'desc',
        numero: 5
    })

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={8} sm={12} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontSize: 40,
                                lineHeight: 1,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Últimas participaciones
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 300,
                                fontSize: 16,
                                lineHeight: 1.4,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                El mundo pertenece a quienes toman acción. Siéntete merecedor y aprovecha esta
                                oportunidad. No dejes tus sueños queden atrás. Aquí tienes la lista de soñadores que ya
                                dieron el primer paso y están listos para alcanzar la victoria. ¡Tú podrías ser el
                                próximo!
                            </Typography>
                        </Grid>

                        {masSM &&
                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                <CabeceraListaParticipantesHome/>
                            </Grid>
                        }

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {data.map((it, index) => {
                                    return (
                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <LineaListaParticipantesHome item={it}/>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                            <Button
                                onClick={() => cargarMas()}
                                fullWidth={sCell} sx={{background: GRADIENTE}}> Ver más
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default ListaGandores

