/**************************************************
 * Nombre:       Formulario_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Email, Password} from "@mui/icons-material";
import {theme} from "../../Tema";


const Formulario_Login = ({...props}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Typography
                    sx={{fontSize: 24, color: theme.palette.primary.main, fontWeight: 800}}>Ingresando</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                <IngresoTexto Icono={Email} nombre={'Correo'} dato={"correo"} {...props}
                              requerido={'El correo de usuario es requerido*'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <IngresoTexto Icono={Password} nombre={'Constraseña'} dato={"pass"} {...props} type={'password'}
                />
            </Grid>


        </Grid>
    )

}
export default Formulario_Login