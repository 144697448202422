// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// dreamfree.colombia@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyCn1xrs-Z8tLNmZvh-4ft5WldQuam3s5aU",
    authDomain: "pagina-95348.firebaseapp.com",
    projectId: "pagina-95348",
    storageBucket: "pagina-95348.appspot.com",
    messagingSenderId: "681239543383",
    appId: "1:681239543383:web:7440f86f11640f706d6d12",
    measurementId: "G-3CB34DNF5T"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)