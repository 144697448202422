/**************************************************
 * Nombre:       Seccion_Subastas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import Tarjeta_Producto from "../Tarjetas/Tarjeta_Producto";
import {useEffect, useState} from "react";
import {where} from "firebase/firestore";
import useRecuperarColleccionListeningToda from "../../Servicios/BD/useRecuperarColleccionListeningToda";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Subastas = () => {
    const {sCell} = useResponsive()
    const [wheres, setWheres] = useState([where('estado', '==', 'Activa')])
    const {data} = useRecuperarColleccionListeningToda({col: 'subastas', orden: 'fecha', wheres: wheres})
    const [wheresCerradas, setWheresCerradas] = useState([where('estado', '==', 'Cerrada')])
    const {data: dataCerradas, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'subastas',
        orden: 'fecha',
        wheres: wheresCerradas,
        numero: 6
    })

    useEffect(() => {
        irArriba()
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3}}
                >

                    <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >


                            <Grid item container lg={6} sm={5} xs={12}
                                  sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                <Typography sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: sCell ? 32 : 40,
                                    fontWeight: 800,
                                    lineHeight: 1.1,
                                    textAlign: sCell ? 'center' : 'right',
                                }}>
                                    Subastas
                                </Typography>
                            </Grid>

                            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-end', marginTop: 2}}>
                                <Typography sx={{
                                    fontWeight: 300,
                                    textAlign: sCell ? 'center' : 'left',
                                    color: theme.palette.primary.main
                                }}>
                                    Puedes adquirir estos productos con nosotros de la manera tradicional (y aburrida) o
                                    disfrutar de un excelente descuento participando en nuestras emocionantes subastas.
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{
                      justifyContent: 'center',
                      backgroundColor: theme.palette.primary.main,
                      marginTop: 6,
                      marginBottom: 8
                  }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3, py: 8}}
                >

                    <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'center'}}>
                                <Typography sx={{
                                    color: '#fff',
                                    fontSize: 24,
                                    fontWeight: 800,
                                    lineHeight: 1.1,
                                    textAlign: sCell ? 'center' : 'right',
                                }}>
                                    Activas
                                </Typography>
                            </Grid>

                            <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >

                                    {data.map((item, index) => {
                                        return (
                                            <Grid item container lg={3} sm={4} xs={12}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <Tarjeta_Producto item={item}/>
                                            </Grid>
                                        )
                                    })
                                    }

                                </Grid>
                            </Grid>


                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sx={{marginTop: 8}}
                            >


                                <Grid item container lg={6} sm={12} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                    <Typography sx={{
                                        color: '#fff',
                                        fontSize: sCell ? 32 : 40,
                                        fontWeight: 800,
                                        lineHeight: 1.1,
                                        textAlign: sCell ? 'center' : 'right',
                                    }}>
                                        Subastas Cerradas
                                    </Typography>
                                </Grid>

                                <Grid item container lg={6} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-end', marginTop: sCell ? 2 : 0}}>
                                    <Typography sx={{
                                        fontWeight: 300,
                                        textAlign: sCell ? 'center' : 'left',
                                        color: '#fff'
                                    }}>
                                        Aquí puedes ver todas las subastas que se activaron y ya se cerraron. Cada
                                        subasta es el sueño cumplido de alguien que tomo acción.
                                    </Typography>
                                </Grid>

                                <Grid item container lg={11} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 6}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                    >

                                        {dataCerradas.map((item, index) => {
                                            return (
                                                <Grid item container lg={3} sm={4} xs={12}
                                                      sx={{justifyContent: 'flex-start'}}>
                                                    <Tarjeta_Producto item={item}/>
                                                </Grid>
                                            )
                                        })
                                        }

                                    </Grid>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'center', marginTop: 4}}>
                                    <Button sx={{backgroundColor: '#fff', color: theme.palette.primary.main}}>Cargar
                                        Mas</Button>
                                </Grid>


                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )

}
export default Seccion_Subastas