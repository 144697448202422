import {collection, getDocs, query} from "firebase/firestore";
import {fire} from "../../fire";

export const obtenerColleccionWhere = ({col, wheres}) => {


    return new Promise(resolve => {

        let q = query(collection(fire, col), ...wheres)

        getDocs(q).then((dox) => {

            let arr = []
            dox.docs.forEach((e) => {
                arr.push(e.data())
            })

            return resolve({res: true, data: arr})
        }).catch((err) => {
            return resolve({res: false, data: err.message})
        })
    })

}