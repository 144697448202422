/**************************************************
 * Nombre:       Tarjeta_Contactos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";


const Tarjeta_Contactos_Footer = ({item, difiere=false}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent={ sCell ? 'center' : "flex-start"}
            alignItems="flex-start"
        >

            <Grid item container lg={1} sm={1} xs={1} sx={{justifyContent: 'center', marginTop: 0.6}}>
                <img src={difiere ? item.img : item.imgblanco} width={item.largo} height={'auto'}/>
            </Grid>

            <Grid item container lg={11} sm={11} xs={11} sx={{justifyContent: 'flex-start', pl: sCell ? 2 : 0}}>
                <Typography sx={{color: difiere ? theme.palette.primary.main : '#fff', fontSize: 16, fontWeight: 300}}>{item.nombre}</Typography>
            </Grid>

        </Grid>
    )

}
export default Tarjeta_Contactos_Footer