/**************************************************
 * Nombre:       BarraCuposLimitados
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Stack, Typography} from "@mui/material";
import {styled} from '@mui/material/styles';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {useEffect, useState} from "react";


const BarraTickets = ({numero, total}) => {

    return (

        <Stack spacing={2} sx={{flexGrow: 1}}>
            <LinearProgress variant="determinate" value={numero} aria-valuemax={total}
                            sx={{height: 10, borderRadius: 4}}/>
        </Stack>

    )

}
export default BarraTickets