/**************************************************
 * Nombre:       ListaGandores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {GRADIENTE} from "../../Constantes";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import CabeceraParticipaciones from "./SubComponetes/CabeceraParticipaciones";
import {React, useContext, useEffect, useState} from "react";
import {where} from "firebase/firestore";
import {usuarioContext} from "../../App";
import useRecuperarColleccionListeningToda from "../../Servicios/BD/useRecuperarColleccionListeningToda";
import LineaListaParticipaciones from "./SubComponetes/LineaListaParticipaciones";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import CabeceraRetos from "./SubComponetes/CabeceraRetos";
import LineaListaRetos from "./SubComponetes/LineaListaRetos";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Reto from "../Dialogos/Dialogo_Reto";
import {set} from "react-hook-form";
import {obtenerColleccionWhere} from "../../Servicios/BD/obtenerColleccionWhere";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";

const ListaRetos = ({usuario}) => {
    const {sCell, masSM} = useResponsive()
    const [recargar, setRecargar] = useState(false)
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Reto, titulo: '', realizado: () => setRecargar(!recargar)})
    const [retos, setRetos] = useState([])
    const [wheres, setWheres] = useState([])
    const {data} = useRecuperarColleccionListeningProg({
        col: 'retos',
        wheres: wheres,
        direccion: 'desc',
    })

    useEffect(() => {

        let idUsuario = usuario.id
        setRetos([])
        if (idUsuario && data.length > 0) {
            let arr = []
            for (let i = 0; i < data.length; i++) {
                console.log(data)
                let w = [where('idReto', '==', data[i].id, where('idUsuario', '==', idUsuario))]
                obtenerColleccionWhere({col: 'evidencias', wheres: w}).then((dox) => {
                    console.log(dox)
                    if (dox.res && dox.data.length > 0) {
                        setRetos((arr) => arr.concat(dox.data))
                    } else {
                        setRetos((arr) => arr.concat(data[i]))
                    }
                })

            }

            setRetos(arr)

        }

    }, [data, recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >


                        {masSM &&
                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                <CabeceraRetos/>
                            </Grid>
                        }

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {retos.map((it, index) => {
                                    return (
                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <LineaListaRetos item={it} abrir={abrir}/>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default ListaRetos

const ganadores = [1, 2, 3, 4]