/**************************************************
 * Nombre:       Dialogo_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logo_color.svg'
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import {theme} from "../../Tema";
import {useContext} from "react";
import {Download, Upload} from "@mui/icons-material";
import {GRADIENTE} from "../../Constantes";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Reto from "../Formularios/Formulario_Reto";
import {usuarioContext} from "../../App";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {saveAs} from 'file-saver'


const Dialogo_Reto = ({cerrar, realizado, datos}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {abrirCargador, Cargador, cerrarCargador} = useLoaders({logo: logo})
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const cData = useContext(usuarioContext)

    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            let obj = entidad
            obj.idReto = entidad.id
            obj.id = new Date().getTime() + 'RET'
            obj.idUsuario = cData.usuario.id
            obj.estado = 'Enviado'
            obj.nick = cData.usuario.nick

            abrirCargador('Subiendo evidencia')
            if (obj.tipo === 'Imagen') {
                subirImagenFile(obj.img, 'evidencia').then((dox) => {
                    if (dox.res) {
                        obj.img = dox.data
                        subirEvidencia(obj)
                    }
                })
            } else {
                subirEvidencia(obj)
            }


        })
    }

    const subirEvidencia = (obj) => {
        guardarDoc('evidencias', obj).then((dox) => {
            if (dox.res) {
                realizado()
                cerrar()
            }
            cerrarCargador()
        })
    }


    async function descargarImg(url) {
        saveAs(url, 'post.jpg')
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: sCell ? 2 : 2}}
        >
            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -2}}>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: 24,
                        fontWeight: 800,
                        lineHeight: 1.1,
                        textAlign: sCell ? 'center' : 'left'
                    }}>{datos.nombre}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Typography sx={{
                    color: theme.palette.secondary.main,
                    fontSize: 16,
                    fontWeight: 300
                }}>{datos.descripcion}</Typography>
            </Grid>

            {datos.img &&
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                    <Button
                        onClick={() => descargarImg(datos.img)}
                        variant={'outlined'} startIcon={<Download/>} sx={{borderRadius: 2, pt: 0.8, pb: 1}}>Descargar
                        Imagen</Button>


                </Grid>
            }

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Typography sx={{
                    color: theme.palette.secondary.main,
                    fontSize: 16,
                    fontWeight: 300
                }}>{`Recompensa Maxima ${datos.numMedallas} medallas`}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Formulario_Reto props={props} isImagen={datos.tipo === 'Imagen'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Button
                    fullWidth
                    onClick={() => guardarDatos()}
                    startIcon={<Upload/>} sx={{background: GRADIENTE, px: 7}}>Enviar Evidencia</Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Reto