/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Portada from "../Componentes/Portada";
import ProductosActivos from "../Componentes/ProductosActivos";
import EconomiaColaborativa from "../Componentes/EconomiaColaborativa";
import ComoFunciona from "../Componentes/ComoFunciona";
import ListaGandores from "../Componentes/ListaGandores";
import NosotrosApartado from "../Componentes/NosotrosApartado";
import {SECCIONES} from "../../Constantes";
import {useContext, useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import PorQueTickets from "../Componentes/PorQueTickets";
import {guardarStringLocal} from "../../Servicios/BDLocal/guardarStringLocal";
import {useParams} from "react-router-dom";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Banner from "../Dialogos/Dialogo_Banner";
import Dialogo_Login from "../Dialogos/Dialogo_Login";
import {usuarioContext} from "../../App";

const Home = () => {
    const {sTab, sCell} = useResponsive()
    const parms = useParams()
    const cData = useContext(usuarioContext)
    const {Dialogo: DialogoLogin, abrir: abrirLogin} = useDialogo({Componente: Dialogo_Login})
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Banner, realizado: abrirLogin})

    useEffect(() => {
        irArriba()
        if (parms && parms.ref) {
            console.log('ref', parms.ref)
            guardarStringLocal({clave: 'referenciado', string: parms.ref})


            setTimeout(() => {
                //mostrar Banner Registro

                abrir()


            }, 7000)

        }
    }, [cData]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Dialogo/>

            <DialogoLogin/>

            <Grid id={SECCIONES[0]} item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: -20}}/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Portada/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sTab ? -4 : 0}}>
                <ProductosActivos/>
            </Grid>

            <Grid id={SECCIONES[3]} item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 0}}/>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 14 : 12}}>
                <EconomiaColaborativa/>
            </Grid>


            <Grid id={SECCIONES[4]} item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start'}}/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                <ComoFunciona/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                <PorQueTickets/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                <ListaGandores/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 8, marginBottom: 8}}>
                <NosotrosApartado/>
            </Grid>


        </Grid>
    )

}
export default Home    