/**************************************************
 * Nombre:       IndicadorHover
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {theme} from "../../../Tema";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";


const IndicadorHover = ({numero, nombre}) => {
    const {masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: masSM ? 24 : 20,
                        fontWeight: 800
                    }}>{numero}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -1}}>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: masSM ? 12 : 10,
                        fontWeight: 300
                    }}>{nombre}</Typography>

            </Grid>

        </Grid>
    )

}
export default IndicadorHover